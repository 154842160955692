import { LOCALSTORAGE_TOKEN_KEY } from "@/Contexts/AuthContext";
import { toast } from "react-toastify";

//* Development URLs
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const API_BASE_ORIGIN = process.env.REACT_APP_API_BASE_ORIGIN;


const handleCatch = (err: Error) => {
  let errMsg = "Something Went Wrong";
  if (err.message) errMsg = err.message;
  toast.error(errMsg);
};

const triggerDownload = (url: string, fileName: string) => {
  const link = document.createElement("a");
  link.href = url;
  link.download = fileName;
  link.setAttribute("download", link.download);
  link.click();
};

const makeReq = async <Req, Res>(
  endpoint: string,
  {
    body,
    fileName,
    onProgress,
    ...others
  }: {
    body?: Req;
    fileName?: string;
    onProgress?: (progress: number) => void;
    customConfig?: Partial<RequestInit>;
  } = {},
  method = "GET",
  download = false,
  abortController?: AbortController,
): Promise<Res> => {
  const token = localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
  const headers: HeadersInit = { "Content-Type": "application/json" };

  if (token) {
    headers.Authorization = `Bearer ${token}`;
  }

  const config: RequestInit = {
    method: method,
    ...others,
    headers: {
      ...headers,
      ...others.customConfig?.headers,
    },
  };

  if (download && abortController) {
    config.signal = abortController.signal;
  }

  if (body) {
    config.body = JSON.stringify(body);
  }

  const res = await fetch(`${API_BASE_URL}${endpoint}`, config);
  if (download && res.body) {
    const totalSize = parseInt(res.headers.get("Content-Length") ?? "", 10);
    const reader = res.body.getReader();
    let loadedSize = 0;

    const chunks = [];
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const { done, value: value_1 } = await reader.read();
      if (done) {
        break;
      }
      chunks.push(value_1);
      loadedSize += value_1.length;
      const progress = (loadedSize / totalSize) * 100;
      if (onProgress) {
        onProgress(progress);
      }
    }

    const data = new Blob(chunks);
    const url = URL.createObjectURL(data);
    const downloadFileName = fileName || `${endpoint.split("/").pop()}`;

    triggerDownload(url, downloadFileName); // Use the triggerDownload function
    return Promise.resolve(data as Res);
  }
  const data_1 = (await res.json()) as Res;
  if (res.ok) {
    return data_1;
  } else {
    return Promise.reject(data_1);
  }
};

export { API_BASE_URL, makeReq, handleCatch, API_BASE_ORIGIN };
