// ----------------------------------------------------------------------

export type Shape = {
  borderRadius: number;
  borderRadiusSm: number;
  borderRadiusMd: number;
};

const shape: Shape = {
  borderRadius: 8,
  borderRadiusSm: 12,
  borderRadiusMd: 16,
};

export default shape;
