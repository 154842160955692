import { filter } from "lodash";
import { ChangeEvent, useContext, useEffect, useState } from "react";

// material
import {
  Alert,
  Card,
  Container,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

// components
import Page from "@/components/Page";
import Scrollbar from "@/components/Scrollbar";
import SearchNotFound from "@/components/SearchNotFound";
import { RadarListHead, RadarListToolbar } from "@/components/_dashboard/radar";

//
//
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { RadarContext, RadarContextType } from "@/Contexts/RadarContext";
import { v4 } from "uuid";
import { format } from "date-fns";

// Utils
import { getTimeTillRemoval } from "@/utils/getTimeTillRemoval";
import { RadarModel } from "@/models/radar";
import { ApplySortFilterProps, getComparator, SortOrder } from "@/utils/sort";
import { DDFilter } from "@/pages/Radars";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    _id: v4(),
    id: "alias",
    label: "Radar alias",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "customer.name",
    label: "Customer",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "software.softwareType",
    label: "Software type",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "software.ubuntuVersion",
    label: "Ubuntu version",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "software.version",
    label: "Software version",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "notes",
    label: "Notes",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "deletedAt",
    label: "Deleted at",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "createdAt",
    label: "Created at",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "createdByUser.name",
    label: "Created by",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "deletedAt",
    label: "Removal countdown",
    alignRight: false,
  },
];

// ----------------------------------------------------------------------

function applySortFilter({
  array = [],
  comparator,
  query,
  selectedDropdownFilter,
}: ApplySortFilterProps<RadarModel> & { selectedDropdownFilter: DDFilter }) {
  const stabilizedThis: [RadarModel, number][] = array.map(
    (el: RadarModel, index: number) => [el, index],
  );
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  let result: RadarModel[] = stabilizedThis.map((el) => el[0]);

  if (query) {
    result = filter(
      result,
      (_radars) =>
        (!!_radars._id &&
          _radars._id.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
        (!!_radars.customer?.name &&
          _radars.customer.name.toLowerCase().indexOf(query.toLowerCase()) !==
            -1),
    );
  }

  if (selectedDropdownFilter === "hide-test") {
    result = filter(result, (_radars) => !_radars.testing);
  } else if (selectedDropdownFilter === "show-test") {
    result = filter(result, (_radars) => _radars.testing);
  }

  return result;
}

export default function DeletedRadars() {
  const { deletedRadars, getDeletedRadars, loading } = useContext(
    RadarContext,
  ) as RadarContextType;

  useEffect(() => {
    const fetchDeletedRadars = async () => {
      await getDeletedRadars();
    };

    fetchDeletedRadars();
  }, []);

  // State
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<SortOrder>("desc");
  const [orderBy, setOrderBy] = useState("deletedAt");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // Dropdown filter
  const [selectedDropdownFilter, setSelectedDropdownFilter] =
    useState<DDFilter>("show-all");

  const navigate = useNavigate();

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterName(event.target.value);
    setPage(0);
  };

  const handleDropdownFilterChange = (event: SelectChangeEvent) => {
    setSelectedDropdownFilter(event.target.value as DDFilter);
    setPage(0);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - (deletedRadars?.length || 0))
      : 0;

  const filteredRadars = applySortFilter({
    array: deletedRadars,
    comparator: getComparator({ order, orderBy }),
    query: filterName,
    selectedDropdownFilter,
  });

  const isRadarNotFound = filteredRadars.length === 0;

  return (
    <Page title="Deleted Radars | Robin Radar Systems Admin">
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to="/" underline="hover" color="inherit">
          Home
        </Link>
        <Typography color="text.primary">Deleted Radars</Typography>
      </Breadcrumbs>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Deleted Radars
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Alert severity="warning">
            Deleted radars are permanently removed from the database after{" "}
            <b>30 days</b>.
          </Alert>
        </Stack>
        <Card>
          <Stack
            direction="row"
            alignItems="flex-start"
            justifyContent="flex-start"
          >
            <RadarListToolbar
              filterName={filterName}
              onFilterName={handleFilterByName}
              slug="for customer or radar ID..."
              selectedDropdownFilter={selectedDropdownFilter}
              handleDropdownFilterChange={handleDropdownFilterChange}
              navigate={navigate}
              showDropdownFilter={true}
            />
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <RadarListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={deletedRadars?.length || 0}
                  onRequestSort={handleRequestSort}
                  noCheckBox
                />
                <TableBody>
                  {loading
                    ? Array(5)
                        .fill(null)
                        .map(() => {
                          return (
                            <TableRow
                              hover
                              key={v4()}
                              tabIndex={-1}
                              role="checkbox"
                              sx={{
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                            >
                              {Array(9)
                                .fill(null)
                                .map(() => (
                                  <TableCell key={v4()} align="right">
                                    <Skeleton />
                                  </TableCell>
                                ))}
                            </TableRow>
                          );
                        })
                    : filteredRadars
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((row) => {
                          const {
                            _id,
                            alias,
                            customer,
                            software,
                            notes,
                            updatedAt,
                            createdAt,
                            deletedAt,
                            createdByUser,
                          } = row;

                          // Pass deletedAt to getTimeTillRemoval
                          const timeTillRemoval = getTimeTillRemoval(deletedAt);

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              sx={{
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                              onClick={() =>
                                navigate(`/dashboard/radars/deleted/${row._id}`)
                              }
                            >
                              <TableCell padding="checkbox"></TableCell>
                              <TableCell align="left">
                                <Tooltip
                                  title={
                                    (alias && alias.length > 0
                                      ? alias
                                      : "N/A") +
                                    " (Radar ID: " +
                                    _id +
                                    ")"
                                  }
                                  placement="top-start"
                                  arrow
                                >
                                  <span>
                                    {alias && alias.length > 0
                                      ? alias.length > 20
                                        ? alias.slice(0, 20) + "..."
                                        : alias
                                      : "N/A"}
                                  </span>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="left">
                                {customer?.name === null
                                  ? "N/A."
                                  : customer?.name}
                              </TableCell>
                              <TableCell align="left">
                                {software?.softwareType === null
                                  ? "N/A."
                                  : software?.softwareType}
                              </TableCell>
                              <TableCell align="left">
                                {software?.ubuntuVersion?.length
                                  ? software?.ubuntuVersion.join(", ")
                                  : " N/A. "}
                              </TableCell>
                              <TableCell align="left">
                                {software?.version === null
                                  ? "N/A."
                                  : software?.version}
                              </TableCell>
                              <TableCell align="left">
                                {notes && notes.length > 0 ? (
                                  <Tooltip
                                    title={notes}
                                    placement="top-start"
                                    arrow
                                  >
                                    <span>
                                      {notes.length > 20
                                        ? notes.slice(0, 20) + "..."
                                        : notes}
                                    </span>
                                  </Tooltip>
                                ) : (
                                  "N/A."
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {format(
                                  new Date(updatedAt),
                                  "yyyy-MM-dd, HH:mm:ss",
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {format(
                                  new Date(createdAt),
                                  "yyyy-MM-dd, HH:mm:ss",
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {createdByUser?.name}
                              </TableCell>
                              <TableCell align="left">
                                {timeTillRemoval.error
                                  ? "N/A"
                                  : `${timeTillRemoval.days} days ${timeTillRemoval.hours} hours`}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isRadarNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={TABLE_HEAD.length + 1}
                        sx={{ py: 3 }}
                      >
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={deletedRadars?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
