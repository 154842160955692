// material
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  LinearProgress,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";

//
import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import NotFound from "@/pages/Page404";

// context
import { BackupContext, BackupContextType } from "@/Contexts/BackupContext";

// components
import Iconify from "@/components/Iconify";
import Page from "@/components/Page";
import { toast } from "react-toastify";
import AlertDialog from "@/components/dialogs/AlertDialog";
import DownloadProgress from "@/components/common/DownloadProgress";

// icons
import RestoreFromTrashIcon from "@mui/icons-material/RestoreFromTrash";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

// breadcrumb
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";

// import for displaying date
import { format } from "date-fns";
import { BackupModel } from "@/models/backup";
import { DownloadStatusType } from "@/utils/types";

//

export default function DeletedSoftwareDetails() {
  type ProcessingPageType = "restoring_backup" | "removing_backup";

  // Contexts
  const {
    backups,
    getBackupById,
    loading,
    deleteBackup,
    downloadBackup,
    restoreBackup,
  } = useContext(BackupContext) as BackupContextType;

  const { id } = useParams();

  const [backup, setBackup] = useState<BackupModel>();
  const [backupNotFound, setBackupNotFound] = useState(false);

  // State for dialogs
  const [openRestoreBackupDialog, setOpenRestoreBackupDialog] = useState(false);
  const [openRemoveBackupDialog, setOpenRemoveBackupDialog] = useState(false);

  // State for download progress
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadStatus, setDownloadStatus] =
    useState<DownloadStatusType | null>(null);
  const [abortController, setAbortController] =
    useState<AbortController | null>(null);
  const [processingPage, setProcessingPage] =
    useState<ProcessingPageType | null>(null);

  const handleProgress = (progress: number) => {
    setDownloadProgress(progress);
    if (progress === 100) {
      setDownloadStatus("completed");
    } else {
      setDownloadStatus("downloading");
    }
  };

  const handleDownloadBackup = async (e: SyntheticEvent) => {
    e.preventDefault();
    const newAbortController = new AbortController();
    setAbortController(newAbortController);
    const versionForDownload = backup?.version;
    // Check if softwareVersion is null
    if (!versionForDownload) {
      toast.error("No backup to download.");
      return;
    }

    // Add .zip at the end if softwareVersion includes '.zip'
    const downloadFileName = versionForDownload.includes(".zip")
      ? versionForDownload
      : `${versionForDownload}.zip`;

    // Reset progress and status before starting a new download
    setDownloadProgress(0);
    setDownloadStatus(null);

    // Start the download
    await downloadBackup({
      id,
      downloadFileName,
      onProgress: handleProgress,
      abortController: newAbortController,
    });
  };

  const handleStopDownload = () => {
    if (abortController) {
      abortController.abort(); // Abort the download
      setDownloadStatus(null); // Reset the download status
      setDownloadProgress(0); // Reset the download progress
      setAbortController(null); // Reset the abortController
    }
  };

  // Handle functions for dialogs
  const handleRestoreBackup = async (e: SyntheticEvent) => {
    if (id) {
      e.preventDefault();
      setProcessingPage("restoring_backup");
      await restoreBackup(id);
      setOpenRestoreBackupDialog(false);
    }
  };

  const handleRemoveBackup = async (e: SyntheticEvent) => {
    if (id) {
      e.preventDefault();
      setProcessingPage("removing_backup");
      await deleteBackup(id);
      setOpenRemoveBackupDialog(false);
    }
  };

  useEffect(() => {
    if (!id) return;
    if (loading) return;

    let el = getBackupById(id);

    // console.log('el', el);

    if (!el) {
      setBackupNotFound(true);
      toast.error("The software with this id does not exist.");
    } else {
      setBackup({ ...el });
    }
  }, [getBackupById, backups, loading, id]);

  return (
    <>
      {backupNotFound || loading ? (
        <Page title="404 | Not Found">
          {loading ? (
            <Skeleton variant="rectangular" height="600px" />
          ) : (
            <NotFound />
          )}
        </Page>
      ) : (
        <Page title={backup?.version + " | Robin Radar Systems"}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              component={RouterLink}
              to="/"
              underline="hover"
              color="inherit"
            >
              Home
            </Link>
            <Link
              component={RouterLink}
              to="/dashboard/softwares/backups"
              underline="hover"
              color="inherit"
            >
              Deleted softwares
            </Link>
            <Typography color="text.primary">{backup?.version}</Typography>
          </Breadcrumbs>
          <Container>
            <Grid justifyContent="flex-start" alignItems="flex-start">
              <Grid item style={{ marginBottom: "1rem" }}>
                <Typography variant="h4" gutterBottom>
                  Deleted software <i>({backup?.version})</i>
                </Typography>
                {!processingPage && (
                  <Card
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                    }}
                  >
                    <Grid container spacing={3}>
                      {/* Left Column (Main Radar Details) */}
                      <Grid item xs={8}>
                        <Stack
                          direction="column"
                          alignItems="flex-start"
                          justifyContent="space-around"
                        >
                          <Typography variant="body1" gutterBottom>
                            <b>ID of removed software: </b> {backup?._id}
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <b>Type: </b>{" "}
                            {backup?.releasedVersionBackup
                              ? "Released"
                              : "NOT released (uploaded via SD API)"}
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <b>Software type: </b> {backup?.softwareType}
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <b>Version: </b>
                            {backup?.version}
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <b>File path: </b> {backup?.backupPath}
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <b>Original (removed) path: </b>{" "}
                            {backup?.softwarePath}
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <b>Radar type(s): </b>
                            {backup?.radarType?.length
                              ? backup.radarType.join(", ")
                              : "N/A"}
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <b>Ubuntu version(s): </b>
                            {backup?.ubuntuVersion?.length
                              ? backup.ubuntuVersion.join(", ")
                              : " - "}
                          </Typography>
                        </Stack>
                      </Grid>

                      {/* Right Column (Deleted Related Info) */}
                      <Grid item xs={4}>
                        <Stack
                          direction="column"
                          alignItems="flex-start"
                          justifyContent="space-around"
                          sx={{
                            backgroundColor: "#f8d7da",
                            padding: "1rem",
                            borderRadius: "0.5rem",
                          }}
                        >
                          <Typography variant="body1" gutterBottom>
                            <b>Removed by user: </b>
                            {backup?.removedBy ? backup.removedBy.name : " - "}
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <b>Removal reason: </b>
                            {backup?.removeReason ? backup.removeReason : " - "}
                          </Typography>
                          <Typography variant="body1" gutterBottom>
                            <b>Remove date: </b>
                            {backup?.createdAt
                              ? format(
                                  new Date(backup.createdAt),
                                  "yyyy-MM-dd, HH:mm:ss"
                                )
                              : " - "}
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>

                    <>
                      <Stack
                        direction="row"
                        alignItems="flex-start"
                        justifyContent="space-between"
                        mt={2}
                        mb={2}
                        gap="2rem"
                      >
                        <Button
                          sx={{ textTransform: "none" }}
                          variant="contained"
                          onClick={
                            downloadStatus === "downloading"
                              ? handleStopDownload
                              : handleDownloadBackup
                          }
                          startIcon={
                            <Iconify
                              icon={
                                downloadStatus === "downloading"
                                  ? "ic:baseline-stop"
                                  : "ic:baseline-download"
                              }
                            />
                          }
                        >
                          {downloadStatus === "downloading"
                            ? "Stop download"
                            : "Download software"}
                        </Button>

                        <Button
                          sx={{
                            textTransform: "none",
                          }}
                          variant="contained"
                          color="warning"
                          onClick={() => setOpenRestoreBackupDialog(true)}
                          startIcon={<RestoreFromTrashIcon />}
                        >
                          Restore file
                        </Button>
                        <Button
                          sx={{
                            textTransform: "none",
                          }}
                          variant="contained"
                          color="error"
                          onClick={() => setOpenRemoveBackupDialog(true)}
                          startIcon={<DeleteForeverIcon />}
                        >
                          Permanent delete
                        </Button>
                      </Stack>
                      <DownloadProgress
                        downloadStatus={downloadStatus}
                        downloadProgress={downloadProgress}
                      />
                    </>
                  </Card>
                )}
                {processingPage && (
                  <Box>
                    <Typography align="center" variant="body1">
                      {processingPage === "restoring_backup" &&
                        "Restoring removed files to the original location..."}
                      {processingPage === "removing_backup" &&
                        "Removing removed files permanently..."}
                    </Typography>
                    <LinearProgress />
                  </Box>
                )}
              </Grid>
            </Grid>
          </Container>
          {openRestoreBackupDialog && (
            <AlertDialog
              onConfirm={handleRestoreBackup}
              onCancel={() => setOpenRestoreBackupDialog(false)}
              title={"Are you sure you want to restore this file?"}
              description={
                "This action will move the software to the system (released or uploaded softwares directory). " +
                "If there is a version of the software already installed with the same version number, no action will be taken."
              }
            />
          )}
          {openRemoveBackupDialog && (
            <AlertDialog
              onConfirm={handleRemoveBackup}
              onCancel={() => setOpenRemoveBackupDialog(false)}
              title={
                "Are you sure you want to permanently remove this software?"
              }
              description={
                "This action will permanentl remove software files from the system. This action cannot be undone."
              }
            />
          )}
        </Page>
      )}
    </>
  );
}
