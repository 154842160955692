import React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { CountryType } from "@/data/countries";

type Props = {
  id?: string;
  resetKey: string;
  minWidth?: number;
  maxWidth?: number;
  countries: CountryType[];
  selectedCountry?: CountryType | null;
  setSelectedCountry: (country: CountryType | null) => void;
};

const CountryAutocomplete = ({
  id,
  resetKey,
  minWidth,
  maxWidth,
  countries,
  selectedCountry,
  setSelectedCountry,
}: Props) => {
  return (
    <Autocomplete
      key={resetKey}
      id={id}
      sx={{ minWidth: minWidth, maxWidth: maxWidth }}
      options={countries}
      autoHighlight
      getOptionLabel={(option) => (option ? option.label : "")}
      isOptionEqualToValue={(option, value) => option.label === value.label}
      value={selectedCountry}
      onChange={(event, newValue) => {
        setSelectedCountry(newValue!);
      }}
      renderOption={(props, option) => (
        <Box
          component="li"
          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading="lazy"
            width="20"
            src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
            alt=""
          />
          {option.label} ({option.code}) +{option.phone}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Radar location (country)"
          inputProps={{
            ...params.inputProps,
            autoComplete: "new-password", // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
};

export default CountryAutocomplete;
