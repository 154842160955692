import React from "react";
// material
import { styled } from "@mui/material/styles";
import { Card, Stack, Container, Typography } from "@mui/material";

// components
import Page from "@/components/Page";
import { MHidden } from "@/components/@material-extend";
import { LoginForm } from "@/components/authentication/login";

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  maxWidth: 480,
  margin: "auto",
  display: "flex",
  minHeight: "100vh",
  flexDirection: "column",
  justifyContent: "center",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login | Robin Radar Systems">
      <MHidden width="mdDown">
        <SectionStyle style={{ zIndex: "10" }}>
          <Typography variant="h3" sx={{ px: 5, mt: 10, mb: 5 }}>
            Hi, Welcome back!
          </Typography>
          <img
            src="/static/illustrations/robin_logo_2.png"
            style={{
              maxWidth: "250px",
              position: "relative",
              left: "50%",
              right: "50%",
              transform: "translate(-50%,-10%)",
            }}
            alt="login"
          />
        </SectionStyle>
      </MHidden>

      <Container maxWidth="sm">
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography variant="h4" gutterBottom>
              Sign in to Robin Radar - S.D. Admin
            </Typography>
            <Typography sx={{ color: "text.secondary" }}>
              Enter your details below.
            </Typography>
          </Stack>

          <LoginForm />
          <div
            style={{
              background:
                "url(//cdn2.hubspot.net/hubfs/6766947/RobinRadarSystems_January2020/Images/footer-bg.png) repeat-x 50% 50%",
              backgroundSize: "contain",
              content: "",
              height: "4.6rem",
              left: 0,
              position: "absolute",
              right: 0,
              top: 0,
              transform: "translateY(150%)",
              zIndex: "5",
              opacity: "50%",
            }}
          ></div>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
