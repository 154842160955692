import {
  ChangeEvent,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";

// other
import { faker } from "@faker-js/faker";
import { filter } from "lodash";
import { v4 } from "uuid";
import { format } from "date-fns";

// material
import {
  Alert,
  Avatar,
  Breadcrumbs,
  Card,
  Container,
  IconButton,
  Link,
  MenuItem,
  Popover,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";

// components
import Label from "@/components/Label";
import Iconify from "@/components/Iconify";
import Page from "@/components/Page";
import Scrollbar from "@/components/Scrollbar";
import SearchNotFound from "@/components/SearchNotFound";
import { UserListHead, UserListToolbar } from "@/components/_dashboard/user";
import AlertDialog from "@/components/dialogs/AlertDialog";

// Contexts
import { UserContext, UserContextType } from "@/Contexts/UserContext";

// Utils
import { getTimeTillRemoval } from "@/utils/getTimeTillRemoval";
import { UserModel, UserRole } from "@/models/user";
import { ApplySortFilterProps, getComparator, SortOrder } from "@/utils/sort";
import { DDFilter } from "@/pages/Users";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { _id: v4(), id: "name", label: "Name", alignRight: false },
  { _id: v4(), id: "email", label: "Email", alignRight: false },
  { _id: v4(), id: "role", label: "Role", alignRight: false },
  { _id: v4(), id: "updatedAt", label: "Deleted at", alignRight: false },
  { _id: v4(), id: "createdAt", label: "Registration", alignRight: false },
  { _id: v4(), id: "activated", label: "Status", alignRight: false },
  { _id: v4(), id: "updatedAt", label: "Removal countdown", alignRight: false },
  { _id: v4(), id: "morevert", label: "", alignRight: false },
];

// ----------------------------------------------------------------------

function applySortFilter({
  array = [],
  comparator,
  query = "",
  roles = [],
  selectedDropdownFilter,
}: ApplySortFilterProps<UserModel> & {
  roles: UserRole[];
  selectedDropdownFilter: DDFilter;
}) {
  const stabilizedThis: [UserModel, number][] = array.map((el, index) => [
    el,
    index,
  ]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  let result = stabilizedThis.map((el) => el[0]);

  // Filtering based on the query (name or email)
  if (query) {
    result = filter(
      result,
      (_user) =>
        _user.name.toLowerCase().includes(query.toLowerCase()) ||
        _user.email.toLowerCase().includes(query.toLowerCase()),
    );
  }

  // Filtering based on roles
  if (roles.length) {
    result = filter(result, (_user) => roles.includes(_user.role));
  }

  // Filtering based on selectedDropdownFilter (show all, hide test, show test)
  if (selectedDropdownFilter === "hide-test") {
    result = filter(result, (_user) => !_user.testing);
  } else if (selectedDropdownFilter === "show-test") {
    result = filter(result, (_user) => _user.testing);
  }

  return result;
}

export default function DeletedUsers() {
  // Contexts
  const {
    deletedUsers,
    getDeletedUsers,
    loading,
    deleteUserPermanently,
    restoreUser,
  } = useContext(UserContext) as UserContextType;

  useEffect(() => {
    const fetchDeletedUsers = async () => {
      await getDeletedUsers();
    };

    fetchDeletedUsers();
  }, [getDeletedUsers]);

  //State
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<SortOrder>("asc");
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState("updatedAt");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState<(EventTarget & Element) | null>(null);
  const [selectedUser, setSelectedUser] = useState<UserModel | null>(null);
  const [selectedRoles, setSelectedRoles] = useState<UserRole[]>([]);

  // Dropdown filter
  const [selectedDropdownFilter, setSelectedDropdownFilter] =
    useState<DDFilter>("show-all");

  // alert dialog
  const [openUserDeleteDialog, setOpenUserDeleteDialog] = useState(false);
  const [openRestoreUserDialog, setOpenRestoreUserDialog] = useState(false);

  const navigate = useNavigate();

  // Handle functions
  const handleOpenMenu = (event: SyntheticEvent, row: UserModel) => {
    event.stopPropagation();
    setSelectedUser(row);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelectedUser(null);
  };

  const handleRestoreUser = async (event: SyntheticEvent) => {
    if (selectedUser) {
      event.stopPropagation();
      await restoreUser(selectedUser._id);
      setOpenRestoreUserDialog(false);
      handleCloseMenu();
    }
  };

  const handleDeleteUser = async (event: SyntheticEvent) => {
    if (selectedUser) {
      event.stopPropagation();
      await deleteUserPermanently(selectedUser._id);
      setOpenUserDeleteDialog(false);
      handleCloseMenu();
    }
  };

  const handleRoleChange = (event: SelectChangeEvent<UserRole[]>) => {
    setPage(0);
    setSelectedRoles(event.target.value as UserRole[]);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = deletedUsers?.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDropdownFilterChange = (event: SelectChangeEvent) => {
    setPage(0);
    setSelectedDropdownFilter(event.target.value as DDFilter);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - (deletedUsers?.length || 0))
      : 0;

  const filteredUsers = applySortFilter({
    array: deletedUsers,
    comparator: getComparator({ order, orderBy }),
    query: filterName,
    roles: selectedRoles,
    selectedDropdownFilter,
  });

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Deleted Users | Robin Radar Systems Admin ">
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to="/" underline="hover" color="inherit">
          Home
        </Link>
        <Typography color="text.primary">Deleted Users</Typography>
      </Breadcrumbs>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Deleted Users
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ mb: 2 }}
        >
          <Alert severity="warning">
            Deleted users are permanently removed from the database after{" "}
            <strong>30 days</strong>.
          </Alert>
        </Stack>
        <Card>
          <UserListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            slug="for name or email..."
            selectedDropdownFilter={selectedDropdownFilter}
            handleDropdownFilterChange={handleDropdownFilterChange}
            selectedRoles={selectedRoles}
            handleRoleChange={handleRoleChange}
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={deletedUsers?.length || 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                  noCheckBox
                />
                <TableBody>
                  {loading
                    ? Array(5)
                        .fill(null)
                        .map(() => {
                          return (
                            <TableRow
                              hover
                              key={v4()}
                              tabIndex={-1}
                              role="checkbox"
                              sx={{ cursor: "pointer", textDecoration: "none" }}
                            >
                              {Array(9)
                                .fill(null)
                                .map(() => (
                                  <TableCell key={v4()} align="right">
                                    <Skeleton />
                                  </TableCell>
                                ))}
                            </TableRow>
                          );
                        })
                    : filteredUsers
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((row) => {
                          const {
                            _id,
                            name,
                            role,
                            activated,
                            email,
                            updatedAt,
                            createdAt,
                            deletedAt,
                          } = row;

                          // Calculate timeTillRemoval here
                          const timeTillRemoval = getTimeTillRemoval(deletedAt);

                          const isItemSelected = selected.indexOf(name) !== -1;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                              sx={{
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                              onClick={() =>
                                navigate(`/dashboard/user/deleted/${row._id}`)
                              }
                            >
                              <TableCell padding="checkbox"></TableCell>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none"
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Avatar
                                    alt={name}
                                    src={`${faker.internet.avatar}`}
                                  />
                                  <Typography variant="subtitle2" noWrap>
                                    {name}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">{email}</TableCell>
                              <TableCell align="left">{role}</TableCell>
                              <TableCell align="left">
                                {updatedAt
                                  ? format(
                                      new Date(updatedAt),
                                      "yyyy-MM-dd, HH:mm:ss",
                                    )
                                  : "N/A"}
                              </TableCell>
                              <TableCell align="left">
                                {format(
                                  new Date(createdAt),
                                  "yyyy-MM-dd, HH:mm:ss",
                                )}
                              </TableCell>
                              <TableCell align="left">
                                <Label
                                  color={
                                    (activated.toString() === "false" &&
                                      "error") ||
                                    "success"
                                  }
                                >
                                  {activated.toString() === "true"
                                    ? " Activated"
                                    : " Not activated"}
                                </Label>
                              </TableCell>
                              <TableCell align="left">
                                {timeTillRemoval.error
                                  ? "N/A"
                                  : `${timeTillRemoval.days} days ${timeTillRemoval.hours} hours`}
                              </TableCell>
                              {/* Edit user: deactivate, ban, remove */}
                              <TableCell
                                sx={{
                                  cursor: "default",
                                  textDecoration: "none",
                                }}
                                align="right"
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                <IconButton
                                  size="small"
                                  color="inherit"
                                  onClick={(event) => {
                                    handleOpenMenu(event, row);
                                  }}
                                >
                                  <Iconify icon={"eva:more-vertical-fill"} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={TABLE_HEAD.length}
                        sx={{ py: 3 }}
                      >
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={deletedUsers?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 200,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={() => setOpenRestoreUserDialog(true)}>
          <Iconify icon={"eva:arrow-back-outline"} sx={{ mr: 2 }} />
          Restore user
        </MenuItem>
        <MenuItem
          onClick={() => setOpenUserDeleteDialog(true)}
          sx={{ color: "error.main" }}
        >
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Permanent delete
        </MenuItem>
      </Popover>
      {openRestoreUserDialog && (
        <AlertDialog
          onConfirm={handleRestoreUser}
          onCancel={() => setOpenRestoreUserDialog(false)}
          title={`Are you sure you want to restore ${selectedUser?.name}?`}
          description={
            "This action will restore this user. The user will be able to login again, but after restoring it, you need to activate it manually."
          }
        />
      )}
      {openUserDeleteDialog && (
        <AlertDialog
          onConfirm={handleDeleteUser}
          onCancel={() => setOpenUserDeleteDialog(false)}
          title={`Are you sure you want to delete ${selectedUser?.name}?`}
          description={
            "This action will delete the user from the system. This action cannot be undone."
          }
        />
      )}
    </Page>
  );
}
