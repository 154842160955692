export type CountryType = {
  id: number;
  code: string;
  label: string;
  phone: string;
};

const countries = [
  {
    code: "AD",
    label: "Andorra",
    phone: "376",
    id: 1,
  },
  {
    code: "AE",
    label: "United Arab Emirates",
    phone: "971",
    id: 2,
  },
  {
    code: "AF",
    label: "Afghanistan",
    phone: "93",
    id: 3,
  },
  {
    code: "AG",
    label: "Antigua and Barbuda",
    phone: "1-268",
    id: 4,
  },
  {
    code: "AI",
    label: "Anguilla",
    phone: "1-264",
    id: 5,
  },
  {
    code: "AL",
    label: "Albania",
    phone: "355",
    id: 6,
  },
  {
    code: "AM",
    label: "Armenia",
    phone: "374",
    id: 7,
  },
  {
    code: "AO",
    label: "Angola",
    phone: "244",
    id: 8,
  },
  {
    code: "AQ",
    label: "Antarctica",
    phone: "672",
    id: 9,
  },
  {
    code: "AR",
    label: "Argentina",
    phone: "54",
    id: 10,
  },
  {
    code: "AS",
    label: "American Samoa",
    phone: "1-684",
    id: 11,
  },
  {
    code: "AT",
    label: "Austria",
    phone: "43",
    id: 12,
  },
  {
    code: "AU",
    label: "Australia",
    phone: "61",
    suggested: true,
    id: 13,
  },
  {
    code: "AW",
    label: "Aruba",
    phone: "297",
    id: 14,
  },
  {
    code: "AX",
    label: "Alland Islands",
    phone: "358",
    id: 15,
  },
  {
    code: "AZ",
    label: "Azerbaijan",
    phone: "994",
    id: 16,
  },
  {
    code: "BA",
    label: "Bosnia and Herzegovina",
    phone: "387",
    id: 17,
  },
  {
    code: "BB",
    label: "Barbados",
    phone: "1-246",
    id: 18,
  },
  {
    code: "BD",
    label: "Bangladesh",
    phone: "880",
    id: 19,
  },
  {
    code: "BE",
    label: "Belgium",
    phone: "32",
    id: 20,
  },
  {
    code: "BF",
    label: "Burkina Faso",
    phone: "226",
    id: 21,
  },
  {
    code: "BG",
    label: "Bulgaria",
    phone: "359",
    id: 22,
  },
  {
    code: "BH",
    label: "Bahrain",
    phone: "973",
    id: 23,
  },
  {
    code: "BI",
    label: "Burundi",
    phone: "257",
    id: 24,
  },
  {
    code: "BJ",
    label: "Benin",
    phone: "229",
    id: 25,
  },
  {
    code: "BL",
    label: "Saint Barthelemy",
    phone: "590",
    id: 26,
  },
  {
    code: "BM",
    label: "Bermuda",
    phone: "1-441",
    id: 27,
  },
  {
    code: "BN",
    label: "Brunei Darussalam",
    phone: "673",
    id: 28,
  },
  {
    code: "BO",
    label: "Bolivia",
    phone: "591",
    id: 29,
  },
  {
    code: "BR",
    label: "Brazil",
    phone: "55",
    id: 30,
  },
  {
    code: "BS",
    label: "Bahamas",
    phone: "1-242",
    id: 31,
  },
  {
    code: "BT",
    label: "Bhutan",
    phone: "975",
    id: 32,
  },
  {
    code: "BV",
    label: "Bouvet Island",
    phone: "47",
    id: 33,
  },
  {
    code: "BW",
    label: "Botswana",
    phone: "267",
    id: 34,
  },
  {
    code: "BY",
    label: "Belarus",
    phone: "375",
    id: 35,
  },
  {
    code: "BZ",
    label: "Belize",
    phone: "501",
    id: 36,
  },
  {
    code: "CA",
    label: "Canada",
    phone: "1",
    suggested: true,
    id: 37,
  },
  {
    code: "CC",
    label: "Cocos (Keeling) Islands",
    phone: "61",
    id: 38,
  },
  {
    code: "CD",
    label: "Congo, Democratic Republic of the",
    phone: "243",
    id: 39,
  },
  {
    code: "CF",
    label: "Central African Republic",
    phone: "236",
    id: 40,
  },
  {
    code: "CG",
    label: "Congo, Republic of the",
    phone: "242",
    id: 41,
  },
  {
    code: "CH",
    label: "Switzerland",
    phone: "41",
    id: 42,
  },
  {
    code: "CI",
    label: "Cote d'Ivoire",
    phone: "225",
    id: 43,
  },
  {
    code: "CK",
    label: "Cook Islands",
    phone: "682",
    id: 44,
  },
  {
    code: "CL",
    label: "Chile",
    phone: "56",
    id: 45,
  },
  {
    code: "CM",
    label: "Cameroon",
    phone: "237",
    id: 46,
  },
  {
    code: "CN",
    label: "China",
    phone: "86",
    id: 47,
  },
  {
    code: "CO",
    label: "Colombia",
    phone: "57",
    id: 48,
  },
  {
    code: "CR",
    label: "Costa Rica",
    phone: "506",
    id: 49,
  },
  {
    code: "CU",
    label: "Cuba",
    phone: "53",
    id: 50,
  },
  {
    code: "CV",
    label: "Cape Verde",
    phone: "238",
    id: 51,
  },
  {
    code: "CW",
    label: "Curacao",
    phone: "599",
    id: 52,
  },
  {
    code: "CX",
    label: "Christmas Island",
    phone: "61",
    id: 53,
  },
  {
    code: "CY",
    label: "Cyprus",
    phone: "357",
    id: 54,
  },
  {
    code: "CZ",
    label: "Czech Republic",
    phone: "420",
    id: 55,
  },
  {
    code: "DE",
    label: "Germany",
    phone: "49",
    suggested: true,
    id: 56,
  },
  {
    code: "DJ",
    label: "Djibouti",
    phone: "253",
    id: 57,
  },
  {
    code: "DK",
    label: "Denmark",
    phone: "45",
    id: 58,
  },
  {
    code: "DM",
    label: "Dominica",
    phone: "1-767",
    id: 59,
  },
  {
    code: "DO",
    label: "Dominican Republic",
    phone: "1-809",
    id: 60,
  },
  {
    code: "DZ",
    label: "Algeria",
    phone: "213",
    id: 61,
  },
  {
    code: "EC",
    label: "Ecuador",
    phone: "593",
    id: 62,
  },
  {
    code: "EE",
    label: "Estonia",
    phone: "372",
    id: 63,
  },
  {
    code: "EG",
    label: "Egypt",
    phone: "20",
    id: 64,
  },
  {
    code: "EH",
    label: "Western Sahara",
    phone: "212",
    id: 65,
  },
  {
    code: "ER",
    label: "Eritrea",
    phone: "291",
    id: 66,
  },
  {
    code: "ES",
    label: "Spain",
    phone: "34",
    id: 67,
  },
  {
    code: "ET",
    label: "Ethiopia",
    phone: "251",
    id: 68,
  },
  {
    code: "FI",
    label: "Finland",
    phone: "358",
    id: 69,
  },
  {
    code: "FJ",
    label: "Fiji",
    phone: "679",
    id: 70,
  },
  {
    code: "FK",
    label: "Falkland Islands (Malvinas)",
    phone: "500",
    id: 71,
  },
  {
    code: "FM",
    label: "Micronesia, Federated States of",
    phone: "691",
    id: 72,
  },
  {
    code: "FO",
    label: "Faroe Islands",
    phone: "298",
    id: 73,
  },
  {
    code: "FR",
    label: "France",
    phone: "33",
    suggested: true,
    id: 74,
  },
  {
    code: "GA",
    label: "Gabon",
    phone: "241",
    id: 75,
  },
  {
    code: "GB",
    label: "United Kingdom",
    phone: "44",
    id: 76,
  },
  {
    code: "GD",
    label: "Grenada",
    phone: "1-473",
    id: 77,
  },
  {
    code: "GE",
    label: "Georgia",
    phone: "995",
    id: 78,
  },
  {
    code: "GF",
    label: "French Guiana",
    phone: "594",
    id: 79,
  },
  {
    code: "GG",
    label: "Guernsey",
    phone: "44",
    id: 80,
  },
  {
    code: "GH",
    label: "Ghana",
    phone: "233",
    id: 81,
  },
  {
    code: "GI",
    label: "Gibraltar",
    phone: "350",
    id: 82,
  },
  {
    code: "GL",
    label: "Greenland",
    phone: "299",
    id: 83,
  },
  {
    code: "GM",
    label: "Gambia",
    phone: "220",
    id: 84,
  },
  {
    code: "GN",
    label: "Guinea",
    phone: "224",
    id: 85,
  },
  {
    code: "GP",
    label: "Guadeloupe",
    phone: "590",
    id: 86,
  },
  {
    code: "GQ",
    label: "Equatorial Guinea",
    phone: "240",
    id: 87,
  },
  {
    code: "GR",
    label: "Greece",
    phone: "30",
    id: 88,
  },
  {
    code: "GS",
    label: "South Georgia and the South Sandwich Islands",
    phone: "500",
    id: 89,
  },
  {
    code: "GT",
    label: "Guatemala",
    phone: "502",
    id: 90,
  },
  {
    code: "GU",
    label: "Guam",
    phone: "1-671",
    id: 91,
  },
  {
    code: "GW",
    label: "Guinea-Bissau",
    phone: "245",
    id: 92,
  },
  {
    code: "GY",
    label: "Guyana",
    phone: "592",
    id: 93,
  },
  {
    code: "HK",
    label: "Hong Kong",
    phone: "852",
    id: 94,
  },
  {
    code: "HM",
    label: "Heard Island and McDonald Islands",
    phone: "672",
    id: 95,
  },
  {
    code: "HN",
    label: "Honduras",
    phone: "504",
    id: 96,
  },
  {
    code: "HR",
    label: "Croatia",
    phone: "385",
    id: 97,
  },
  {
    code: "HT",
    label: "Haiti",
    phone: "509",
    id: 98,
  },
  {
    code: "HU",
    label: "Hungary",
    phone: "36",
    id: 99,
  },
  {
    code: "ID",
    label: "Indonesia",
    phone: "62",
    id: 100,
  },
  {
    code: "IE",
    label: "Ireland",
    phone: "353",
    id: 101,
  },
  {
    code: "IL",
    label: "Israel",
    phone: "972",
    id: 102,
  },
  {
    code: "IM",
    label: "Isle of Man",
    phone: "44",
    id: 103,
  },
  {
    code: "IN",
    label: "India",
    phone: "91",
    id: 104,
  },
  {
    code: "IO",
    label: "British Indian Ocean Territory",
    phone: "246",
    id: 105,
  },
  {
    code: "IQ",
    label: "Iraq",
    phone: "964",
    id: 106,
  },
  {
    code: "IR",
    label: "Iran, Islamic Republic of",
    phone: "98",
    id: 107,
  },
  {
    code: "IS",
    label: "Iceland",
    phone: "354",
    id: 108,
  },
  {
    code: "IT",
    label: "Italy",
    phone: "39",
    id: 109,
  },
  {
    code: "JE",
    label: "Jersey",
    phone: "44",
    id: 110,
  },
  {
    code: "JM",
    label: "Jamaica",
    phone: "1-876",
    id: 111,
  },
  {
    code: "JO",
    label: "Jordan",
    phone: "962",
    id: 112,
  },
  {
    code: "JP",
    label: "Japan",
    phone: "81",
    suggested: true,
    id: 113,
  },
  {
    code: "KE",
    label: "Kenya",
    phone: "254",
    id: 114,
  },
  {
    code: "KG",
    label: "Kyrgyzstan",
    phone: "996",
    id: 115,
  },
  {
    code: "KH",
    label: "Cambodia",
    phone: "855",
    id: 116,
  },
  {
    code: "KI",
    label: "Kiribati",
    phone: "686",
    id: 117,
  },
  {
    code: "KM",
    label: "Comoros",
    phone: "269",
    id: 118,
  },
  {
    code: "KN",
    label: "Saint Kitts and Nevis",
    phone: "1-869",
    id: 119,
  },
  {
    code: "KP",
    label: "Korea, Democratic People's Republic of",
    phone: "850",
    id: 120,
  },
  {
    code: "KR",
    label: "Korea, Republic of",
    phone: "82",
    id: 121,
  },
  {
    code: "KW",
    label: "Kuwait",
    phone: "965",
    id: 122,
  },
  {
    code: "KY",
    label: "Cayman Islands",
    phone: "1-345",
    id: 123,
  },
  {
    code: "KZ",
    label: "Kazakhstan",
    phone: "7",
    id: 124,
  },
  {
    code: "LA",
    label: "Lao People's Democratic Republic",
    phone: "856",
    id: 125,
  },
  {
    code: "LB",
    label: "Lebanon",
    phone: "961",
    id: 126,
  },
  {
    code: "LC",
    label: "Saint Lucia",
    phone: "1-758",
    id: 127,
  },
  {
    code: "LI",
    label: "Liechtenstein",
    phone: "423",
    id: 128,
  },
  {
    code: "LK",
    label: "Sri Lanka",
    phone: "94",
    id: 129,
  },
  {
    code: "LR",
    label: "Liberia",
    phone: "231",
    id: 130,
  },
  {
    code: "LS",
    label: "Lesotho",
    phone: "266",
    id: 131,
  },
  {
    code: "LT",
    label: "Lithuania",
    phone: "370",
    id: 132,
  },
  {
    code: "LU",
    label: "Luxembourg",
    phone: "352",
    id: 133,
  },
  {
    code: "LV",
    label: "Latvia",
    phone: "371",
    id: 134,
  },
  {
    code: "LY",
    label: "Libya",
    phone: "218",
    id: 135,
  },
  {
    code: "MA",
    label: "Morocco",
    phone: "212",
    id: 136,
  },
  {
    code: "MC",
    label: "Monaco",
    phone: "377",
    id: 137,
  },
  {
    code: "MD",
    label: "Moldova, Republic of",
    phone: "373",
    id: 138,
  },
  {
    code: "ME",
    label: "Montenegro",
    phone: "382",
    id: 139,
  },
  {
    code: "MF",
    label: "Saint Martin (French part)",
    phone: "590",
    id: 140,
  },
  {
    code: "MG",
    label: "Madagascar",
    phone: "261",
    id: 141,
  },
  {
    code: "MH",
    label: "Marshall Islands",
    phone: "692",
    id: 142,
  },
  {
    code: "MK",
    label: "Macedonia, the Former Yugoslav Republic of",
    phone: "389",
    id: 143,
  },
  {
    code: "ML",
    label: "Mali",
    phone: "223",
    id: 144,
  },
  {
    code: "MM",
    label: "Myanmar",
    phone: "95",
    id: 145,
  },
  {
    code: "MN",
    label: "Mongolia",
    phone: "976",
    id: 146,
  },
  {
    code: "MO",
    label: "Macao",
    phone: "853",
    id: 147,
  },
  {
    code: "MP",
    label: "Northern Mariana Islands",
    phone: "1-670",
    id: 148,
  },
  {
    code: "MQ",
    label: "Martinique",
    phone: "596",
    id: 149,
  },
  {
    code: "MR",
    label: "Mauritania",
    phone: "222",
    id: 150,
  },
  {
    code: "MS",
    label: "Montserrat",
    phone: "1-664",
    id: 151,
  },
  {
    code: "MT",
    label: "Malta",
    phone: "356",
    id: 152,
  },
  {
    code: "MU",
    label: "Mauritius",
    phone: "230",
    id: 153,
  },
  {
    code: "MV",
    label: "Maldives",
    phone: "960",
    id: 154,
  },
  {
    code: "MW",
    label: "Malawi",
    phone: "265",
    id: 155,
  },
  {
    code: "MX",
    label: "Mexico",
    phone: "52",
    id: 156,
  },
  {
    code: "MY",
    label: "Malaysia",
    phone: "60",
    id: 157,
  },
  {
    code: "MZ",
    label: "Mozambique",
    phone: "258",
    id: 158,
  },
  {
    code: "NA",
    label: "Namibia",
    phone: "264",
    id: 159,
  },
  {
    code: "NC",
    label: "New Caledonia",
    phone: "687",
    id: 160,
  },
  {
    code: "NE",
    label: "Niger",
    phone: "227",
    id: 161,
  },
  {
    code: "NF",
    label: "Norfolk Island",
    phone: "672",
    id: 162,
  },
  {
    code: "NG",
    label: "Nigeria",
    phone: "234",
    id: 163,
  },
  {
    code: "NI",
    label: "Nicaragua",
    phone: "505",
    id: 164,
  },
  {
    code: "NL",
    label: "Netherlands",
    phone: "31",
    id: 165,
  },
  {
    code: "NO",
    label: "Norway",
    phone: "47",
    id: 166,
  },
  {
    code: "NP",
    label: "Nepal",
    phone: "977",
    id: 167,
  },
  {
    code: "NR",
    label: "Nauru",
    phone: "674",
    id: 168,
  },
  {
    code: "NU",
    label: "Niue",
    phone: "683",
    id: 169,
  },
  {
    code: "NZ",
    label: "New Zealand",
    phone: "64",
    id: 170,
  },
  {
    code: "OM",
    label: "Oman",
    phone: "968",
    id: 171,
  },
  {
    code: "PA",
    label: "Panama",
    phone: "507",
    id: 172,
  },
  {
    code: "PE",
    label: "Peru",
    phone: "51",
    id: 173,
  },
  {
    code: "PF",
    label: "French Polynesia",
    phone: "689",
    id: 174,
  },
  {
    code: "PG",
    label: "Papua New Guinea",
    phone: "675",
    id: 175,
  },
  {
    code: "PH",
    label: "Philippines",
    phone: "63",
    id: 176,
  },
  {
    code: "PK",
    label: "Pakistan",
    phone: "92",
    id: 177,
  },
  {
    code: "PL",
    label: "Poland",
    phone: "48",
    id: 178,
  },
  {
    code: "PM",
    label: "Saint Pierre and Miquelon",
    phone: "508",
    id: 179,
  },
  {
    code: "PN",
    label: "Pitcairn",
    phone: "870",
    id: 180,
  },
  {
    code: "PR",
    label: "Puerto Rico",
    phone: "1",
    id: 181,
  },
  {
    code: "PS",
    label: "Palestine, State of",
    phone: "970",
    id: 182,
  },
  {
    code: "PT",
    label: "Portugal",
    phone: "351",
    id: 183,
  },
  {
    code: "PW",
    label: "Palau",
    phone: "680",
    id: 184,
  },
  {
    code: "PY",
    label: "Paraguay",
    phone: "595",
    id: 185,
  },
  {
    code: "QA",
    label: "Qatar",
    phone: "974",
    id: 186,
  },
  {
    code: "RE",
    label: "Reunion",
    phone: "262",
    id: 187,
  },
  {
    code: "RO",
    label: "Romania",
    phone: "40",
    id: 188,
  },
  {
    code: "RS",
    label: "Serbia",
    phone: "381",
    id: 189,
  },
  {
    code: "RU",
    label: "Russian Federation",
    phone: "7",
    id: 190,
  },
  {
    code: "RW",
    label: "Rwanda",
    phone: "250",
    id: 191,
  },
  {
    code: "SA",
    label: "Saudi Arabia",
    phone: "966",
    id: 192,
  },
  {
    code: "SB",
    label: "Solomon Islands",
    phone: "677",
    id: 193,
  },
  {
    code: "SC",
    label: "Seychelles",
    phone: "248",
    id: 194,
  },
  {
    code: "SD",
    label: "Sudan",
    phone: "249",
    id: 195,
  },
  {
    code: "SE",
    label: "Sweden",
    phone: "46",
    id: 196,
  },
  {
    code: "SG",
    label: "Singapore",
    phone: "65",
    id: 197,
  },
  {
    code: "SH",
    label: "Saint Helena",
    phone: "290",
    id: 198,
  },
  {
    code: "SI",
    label: "Slovenia",
    phone: "386",
    id: 199,
  },
  {
    code: "SJ",
    label: "Svalbard and Jan Mayen",
    phone: "47",
    id: 200,
  },
  {
    code: "SK",
    label: "Slovakia",
    phone: "421",
    id: 201,
  },
  {
    code: "SL",
    label: "Sierra Leone",
    phone: "232",
    id: 202,
  },
  {
    code: "SM",
    label: "San Marino",
    phone: "378",
    id: 203,
  },
  {
    code: "SN",
    label: "Senegal",
    phone: "221",
    id: 204,
  },
  {
    code: "SO",
    label: "Somalia",
    phone: "252",
    id: 205,
  },
  {
    code: "SR",
    label: "Suriname",
    phone: "597",
    id: 206,
  },
  {
    code: "SS",
    label: "South Sudan",
    phone: "211",
    id: 207,
  },
  {
    code: "ST",
    label: "Sao Tome and Principe",
    phone: "239",
    id: 208,
  },
  {
    code: "SV",
    label: "El Salvador",
    phone: "503",
    id: 209,
  },
  {
    code: "SX",
    label: "Sint Maarten (Dutch part)",
    phone: "1-721",
    id: 210,
  },
  {
    code: "SY",
    label: "Syrian Arab Republic",
    phone: "963",
    id: 211,
  },
  {
    code: "SZ",
    label: "Swaziland",
    phone: "268",
    id: 212,
  },
  {
    code: "TC",
    label: "Turks and Caicos Islands",
    phone: "1-649",
    id: 213,
  },
  {
    code: "TD",
    label: "Chad",
    phone: "235",
    id: 214,
  },
  {
    code: "TF",
    label: "French Southern Territories",
    phone: "262",
    id: 215,
  },
  {
    code: "TG",
    label: "Togo",
    phone: "228",
    id: 216,
  },
  {
    code: "TH",
    label: "Thailand",
    phone: "66",
    id: 217,
  },
  {
    code: "TJ",
    label: "Tajikistan",
    phone: "992",
    id: 218,
  },
  {
    code: "TK",
    label: "Tokelau",
    phone: "690",
    id: 219,
  },
  {
    code: "TL",
    label: "Timor-Leste",
    phone: "670",
    id: 220,
  },
  {
    code: "TM",
    label: "Turkmenistan",
    phone: "993",
    id: 221,
  },
  {
    code: "TN",
    label: "Tunisia",
    phone: "216",
    id: 222,
  },
  {
    code: "TO",
    label: "Tonga",
    phone: "676",
    id: 223,
  },
  {
    code: "TR",
    label: "Turkey",
    phone: "90",
    id: 224,
  },
  {
    code: "TT",
    label: "Trinidad and Tobago",
    phone: "1-868",
    id: 225,
  },
  {
    code: "TV",
    label: "Tuvalu",
    phone: "688",
    id: 226,
  },
  {
    code: "TW",
    label: "Taiwan, Republic of China",
    phone: "886",
    id: 227,
  },
  {
    code: "TZ",
    label: "United Republic of Tanzania",
    phone: "255",
    id: 228,
  },
  {
    code: "UA",
    label: "Ukraine",
    phone: "380",
    id: 229,
  },
  {
    code: "UG",
    label: "Uganda",
    phone: "256",
    id: 230,
  },
  {
    code: "US",
    label: "United States",
    phone: "1",
    suggested: true,
    id: 231,
  },
  {
    code: "UY",
    label: "Uruguay",
    phone: "598",
    id: 232,
  },
  {
    code: "UZ",
    label: "Uzbekistan",
    phone: "998",
    id: 233,
  },
  {
    code: "VA",
    label: "Holy See (Vatican City State)",
    phone: "379",
    id: 234,
  },
  {
    code: "VC",
    label: "Saint Vincent and the Grenadines",
    phone: "1-784",
    id: 235,
  },
  {
    code: "VE",
    label: "Venezuela",
    phone: "58",
    id: 236,
  },
  {
    code: "VG",
    label: "British Virgin Islands",
    phone: "1-284",
    id: 237,
  },
  {
    code: "VI",
    label: "US Virgin Islands",
    phone: "1-340",
    id: 238,
  },
  {
    code: "VN",
    label: "Vietnam",
    phone: "84",
    id: 239,
  },
  {
    code: "VU",
    label: "Vanuatu",
    phone: "678",
    id: 240,
  },
  {
    code: "WF",
    label: "Wallis and Futuna",
    phone: "681",
    id: 241,
  },
  {
    code: "WS",
    label: "Samoa",
    phone: "685",
    id: 242,
  },
  {
    code: "XK",
    label: "Kosovo",
    phone: "383",
    id: 243,
  },
  {
    code: "YE",
    label: "Yemen",
    phone: "967",
    id: 244,
  },
  {
    code: "YT",
    label: "Mayotte",
    phone: "262",
    id: 245,
  },
  {
    code: "ZA",
    label: "South Africa",
    phone: "27",
    id: 246,
  },
  {
    code: "ZM",
    label: "Zambia",
    phone: "260",
    id: 247,
  },
  {
    code: "ZW",
    label: "Zimbabwe",
    phone: "263",
    id: 248,
  },
];

export default countries
