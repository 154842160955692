import React, { ChangeEvent, useContext, useEffect, useState } from "react";
import { filter } from "lodash";
import { useParams } from "react-router";
import {
  Grid,
  Tooltip,
  Typography,
  Card,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import TablePagination from "@mui/material/TablePagination";
import { v4 } from "uuid";

import { RadarContext, RadarContextType } from "@/Contexts/RadarContext";
import Label from "@/components/Label";
import SearchNotFound from "@/components/SearchNotFound";
import Scrollbar from "@/components/Scrollbar";
import { RadarListHead, RadarListToolbar } from "@/components/_dashboard/radar";
import { ApplySortFilterProps, getComparator, SortOrder } from "@/utils/sort";
import { RadarHistoryModel } from "@/models/radarHistory";

const TABLE_HEAD = [
  { _id: v4(), id: "version", label: "Version", alignRight: false },
  { _id: v4(), id: "softwareType", label: "Software type", alignRight: false },
  { _id: v4(), id: "radarType", label: "Radar type", alignRight: false },
  { _id: v4(), id: "customerName", label: "Customer name", alignRight: false },
  {
    _id: v4(),
    id: "notes",
    label: "Notes",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "updatedByUser",
    label: "Updated by user",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "updatedAt",
    label: "Updated at",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "updateType",
    label: "Update type",
    alignRight: false,
  },
];

const RadarHistory = () => {
  const { getRadarHistory } = useContext(RadarContext) as RadarContextType;
  const { id } = useParams();

  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<SortOrder>("desc");
  const [orderBy, setOrderBy] = useState("updatedAt");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filteredRadarsState, setFilteredRadarsState] = useState<
    RadarHistoryModel[]
  >([]);
  const [isRadarNotFoundState, setIsRadarNotFoundState] = useState(false);
  const [historyState, setHistoryState] = useState<RadarHistoryModel[]>([]);

  function applySortFilter({
    array = [],
    comparator,
    query,
  }: ApplySortFilterProps<RadarHistoryModel>) {
    if (!array) {
      return [];
    }

    const stabilizedThis: [RadarHistoryModel, number][] = array.map(
      (el, index) => [el, index],
    );
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });

    if (query) {
      return filter(
        array,
        (_radars) =>
          String(_radars.version).toLowerCase().indexOf(query.toLowerCase()) !==
          -1,
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  useEffect(() => {
    if (!id) return;
    const fetchHistory = async () => {
      const history = (await getRadarHistory(id)) ?? [];

      const filteredRadars = applySortFilter({
        array: history,
        comparator: getComparator({ order, orderBy }),
        query: filterName,
      });
      const isRadarNotFound = filteredRadars.length === 0;

      setFilteredRadarsState(filteredRadars);
      setIsRadarNotFoundState(isRadarNotFound);
      setHistoryState(history);
    };

    fetchHistory();
  }, [getRadarHistory, id, order, orderBy, filterName]);

  const updateFilteredRadars = () => {
    const filteredRadars = applySortFilter({
      array: historyState,
      comparator: getComparator({ order, orderBy }),
      query: filterName,
    });
    const isRadarNotFound = filteredRadars.length === 0;

    setFilteredRadarsState(filteredRadars);
    setIsRadarNotFoundState(isRadarNotFound);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    updateFilteredRadars();
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event: ChangeEvent<HTMLInputElement>) => {
    setFilterName(event.target.value);
    updateFilteredRadars();
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - (historyState?.length || 0))
      : 0;

  return (
    <Grid item>
      <Typography variant="h4" gutterBottom>
        Radar change history
      </Typography>
      <Typography variant="body2" gutterBottom>
        Please note: Only the most recent 15 changes will be retained in the
        history change list.
      </Typography>
      <Card
        sx={{
          p: 2,
          display: "flex",
          flexDirection: "column",
          height: "100%",
          minWidth: "60vw",
          maxWidth: "32.5rem",
        }}
      >
        <RadarListToolbar
          filterName={filterName}
          onFilterName={handleFilterByName}
          slug="for version..."
        />

        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <RadarListHead
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={window.history?.length || 0}
                onRequestSort={handleRequestSort}
                noCheckBox
              />
              <TableBody>
                {historyState === null
                  ? Array(5)
                      .fill(null)
                      .map(() => {
                        return (
                          <TableRow
                            hover
                            key={v4()}
                            tabIndex={-1}
                            role="checkbox"
                            sx={{
                              cursor: "pointer",
                              textDecoration: "none",
                            }}
                          >
                            {Array(10)
                              .fill(null)
                              .map(() => (
                                <TableCell key={v4()} align="right">
                                  <Skeleton />
                                </TableCell>
                              ))}
                          </TableRow>
                        );
                      })
                  : filteredRadarsState

                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage,
                      )
                      .map((row) => {
                        const {
                          _id,
                          version,
                          softwareType,
                          radarType,
                          customerName,
                          notes,
                          updatedByUser,
                          updatedAt,
                          updateType,
                        } = row;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            sx={{
                              textDecoration: "none",
                            }}
                          >
                            <TableCell padding="checkbox"></TableCell>
                            <TableCell align="left">
                              {version || "N/A"}
                            </TableCell>
                            <TableCell align="left">
                              {softwareType || "N/A"}
                            </TableCell>
                            <TableCell align="left">
                              {radarType?.join(", ") || "N/A"}
                            </TableCell>
                            <TableCell align="left">
                              {customerName || "N/A"}
                            </TableCell>
                            <TableCell align="left">
                              <Tooltip title={notes || "N/A"}>
                                <span>
                                  {notes?.length > 6
                                    ? notes.slice(0, 5) + "..."
                                    : notes || "N/A"}
                                </span>
                              </Tooltip>
                            </TableCell>
                            <TableCell align="left">
                              {updatedByUser || "N/A"}
                            </TableCell>
                            <TableCell align="left">
                              {updatedAt
                                ? new Intl.DateTimeFormat("en-US", {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "2-digit",
                                    minute: "2-digit",
                                    second: "2-digit",
                                    hour12: false,
                                    timeZone: "Europe/Amsterdam",
                                  }).format(new Date(updatedAt))
                                : "N/A"}
                            </TableCell>

                            <TableCell align="left">
                              <Label
                                color={
                                  updateType.toString() === "R"
                                    ? "warning"
                                    : updateType.toString() === "U"
                                    ? "error"
                                    : "success"
                                }
                              >
                                {updateType.toString() === "U"
                                  ? " Unassign software"
                                  : updateType.toString() === "R"
                                  ? " Radar details"
                                  : " Assign software"}
                              </Label>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              {isRadarNotFoundState && (
                <TableBody>
                  <TableRow>
                    <TableCell
                      align="center"
                      colSpan={TABLE_HEAD.length + 1}
                      sx={{ py: 3 }}
                    >
                      <SearchNotFound searchQuery={filterName} />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 50, 100]}
          component="div"
          count={historyState?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(_, newPage) => setPage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </Grid>
  );
};

export default RadarHistory;
