import React, {
  ChangeEvent,
  MouseEvent,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useParams } from "react-router";

// material-ui
import {
  Button,
  Card,
  Checkbox,
  Container,
  Grid,
  Skeleton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import NotFound from "@/pages/Page404";
import Page from "@/components/Page";
import { UserContext, UserContextType } from "@/Contexts/UserContext";
import { AuthContext, AuthContextType } from "@/Contexts/AuthContext";
import { toast } from "react-toastify";
import Iconify from "@/components/Iconify";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import SyncLockIcon from "@mui/icons-material/SyncLock";

// breadcrumb
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";

//
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

// Generate password function
import { generatePassword } from "@/utils/generatePassword";
import { UserModel, UserRole } from "@/models/user";

export default function EditUser() {
  // Context
  const { users, editUser, getUserById, loading } = useContext(
    UserContext,
  ) as UserContextType;
  const { changeUserPassword } = useContext(AuthContext) as AuthContextType;

  const { id } = useParams();

  // States
  const [user, setUser] = useState<UserModel>();
  const [userNotFound, setUserNotFound] = useState(false);
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState<UserRole>();
  const [status, setStatus] = useState<boolean>();
  const [testing, setTesting] = useState(false);

  // Handle functions
  // Generate password function, 15-20 chars default if no length is passed
  function handleGeneratePassword() {
    const password = generatePassword();
    setValues({ ...values, password });
  }

  const handleChange =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  useEffect(() => {
    if (!id) return;
    if (loading) return;

    let el = getUserById(id);

    if (!el) {
      setUserNotFound(true);
      toast.error("The user with this id doesn't exist.");
    } else {
      setUser({ ...el });
      // set values for fields
      setFullName(el.name);
      setEmail(el.email);
      setPhone(el.phone);
      setRole(el.role);
      setStatus(el.activated);
      setTesting(el.testing);
    }
  }, [getUserById, users, loading, id]);

  // transfer values from other fields to REQUEST
  const transferValue = async (event: MouseEvent<HTMLButtonElement>) => {
    if (user) {
      event.preventDefault();
      await changeUserPassword(values.password, user._id);
      clearState();
    }
  };

  const saveChanges = async (event: SyntheticEvent) => {
    event.preventDefault();
    if (user) {
      await editUser(user._id, {
        name: fullName,
        email: email,
        phone: phone,
        role: role,
        activated: status,
        testing: testing,
      });
    }
  };

  //   clear state after a request has been sent
  const clearState = () => {
    setValues({
      ...values,
      password: "",
    });
  };

  return (
    <>
      {userNotFound || loading ? (
        <Page title="404 | Not Found">
          {loading ? (
            <Skeleton variant="rectangular" height="600px" />
          ) : (
            <NotFound />
          )}
        </Page>
      ) : (
        <Page title={"Edit " + user?.name + " | Robin Radar Systems Admin"}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              component={RouterLink}
              to="/"
              underline="hover"
              color="inherit"
            >
              Home
            </Link>
            <Link
              component={RouterLink}
              to="/dashboard/user/list"
              underline="hover"
              color="inherit"
            >
              Users
            </Link>
            <Typography color="text.primary">Edit {user?.name}</Typography>
          </Breadcrumbs>
          <Container>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              sx={{ mt: 1 }}
            >
              <Grid item>
                <Typography variant="h4" gutterBottom>
                  {user?.name}
                </Typography>
                <Card
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    minWidth: "50vw",
                  }}
                >
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    spacing={2}
                    mb={2}
                  >
                    <TextField
                      required
                      error={fullName === ""}
                      id="fullname"
                      label="Full Name"
                      variant="outlined"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      autoComplete="off"
                      sx={{ width: 300 }}
                    />

                    <TextField
                      required
                      error={email === ""}
                      id="email"
                      label="Email Address"
                      variant="outlined"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      autoComplete="off"
                      sx={{ width: 300 }}
                    />
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    spacing={2}
                    mb={2}
                  >
                    <TextField
                      id="phone"
                      label="Phone Number"
                      variant="outlined"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      autoComplete="off"
                      sx={{ width: 300 }}
                    />

                    <FormControl sx={{ width: 300 }}>
                      <InputLabel id="roleid">Role</InputLabel>
                      <Select
                        labelId="selectrole-label"
                        id="selectrole"
                        value={role}
                        label="Role"
                        onChange={(e) => setRole(e.target.value as UserRole)}
                      >
                        <MenuItem value={"customer"}>Customer</MenuItem>
                        <MenuItem value={"admin"}>Admin</MenuItem>
                        <MenuItem value={"S&S"}>S&S</MenuItem>
                        <MenuItem value={"R&D"}>R&D</MenuItem>
                      </Select>
                    </FormControl>
                  </Stack>
                  <Stack
                    direction="row"
                    justifyContent="flex-start"
                    spacing={2}
                    mb={2}
                  >
                    <FormControlLabel
                      control={
                        <Switch
                          checked={Boolean(status)}
                          onChange={(e) => setStatus(e.target.checked)}
                          name="isActive"
                        />
                      }
                      label={status ? "Active" : "Inactive"}
                    />
                    {/* Add a switch button for edit if radar is for testing or not */}
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(testing)}
                          onChange={(e) => setTesting(e.target.checked)}
                          name="isTesting"
                        />
                      }
                      label="Test user"
                    />
                  </Stack>
                  {/* add Save changes button */}
                  <Stack direction="row" justifyContent="right">
                    <Button
                      sx={{ textTransform: "none" }}
                      variant="contained"
                      color="primary"
                      onClick={saveChanges}
                      disabled={!fullName || !email}
                      startIcon={
                        <Iconify icon="eva:save-outline" color="#ffffff" />
                      }
                    >
                      Save changes
                    </Button>
                  </Stack>
                </Card>
              </Grid>
              <Grid item>
                <Typography variant="h5" gutterBottom>
                  Update user password
                </Typography>
                <Card
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",

                    minWidth: "50vw",
                  }}
                >
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                    mt={2}
                    gap="10px"
                  >
                    <Tooltip
                      title="Generate a random password"
                      aria-label="generate a random password"
                    >
                      <IconButton
                        aria-label="generate a random password"
                        onClick={handleGeneratePassword}
                        size="medium"
                        sx={{
                          color: "white",
                          bgcolor: "primary.main",
                          ":hover": { bgcolor: "primary.dark" },
                        }}
                      >
                        <SyncLockIcon />
                      </IconButton>
                    </Tooltip>
                    <FormControl variant="outlined" sx={{ width: 300 }}>
                      <InputLabel htmlFor="outlined-adornment-password">
                        New password
                      </InputLabel>
                      <OutlinedInput
                        id="outlined-adornment-password"
                        type={values.showPassword ? "text" : "password"}
                        value={values.password}
                        onChange={handleChange("password")}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {values.showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                        label="Password"
                      />
                    </FormControl>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="left"
                    justifyContent="right"
                    mt={1}
                    gap="10px"
                  >
                    <Button
                      variant="contained"
                      sx={{ textTransform: "none" }}
                      onClick={transferValue}
                      disabled={!values.password}
                      startIcon={
                        <Iconify icon="eva:save-outline" color="#ffffff" />
                      }
                    >
                      Update password
                    </Button>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Page>
      )}
    </>
  );
}
