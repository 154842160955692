import { AnimationProps, motion } from "framer-motion";
// material
import { Box } from "@mui/material";
//
import { varWrapEnter } from "./variants";
import { ChildrenProp } from "@/utils/types";

// ----------------------------------------------------------------------

type Props = AnimationProps & {
  children: ChildrenProp;
  open: boolean;
};

export function MotionContainer({ open, children, ...other }: Props) {
  return (
    <Box
      component={motion.div}
      initial={false}
      animate={open ? "animate" : "exit"}
      variants={varWrapEnter}
      {...other}
    >
      {children}
    </Box>
  );
}
