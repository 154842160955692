import { useArray } from "@/hooks";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { handleCatch, makeReq } from "@/utils/makeReq";
import { AuthContext, AuthContextType } from "@/Contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { ChildrenProp, IndexedObject } from "@/utils/types";
import { UserModel } from "@/models/user";
import { RadarDTO, RadarModel } from "@/models/radar";
import { APIResponse, CallbackProp, DownloadInput } from "@/Contexts/types";
import { RadarHistoryModel } from "@/models/radarHistory";

export type RadarContextType = {
  loading: boolean;
  radars: RadarModel[];
  filteredRadars: RadarModel[];
  getRadarById: (id: string) => RadarModel | undefined;
  createRadar: (val: RadarDTO, callback?: CallbackProp) => Promise<void>;
  deleteRadar: (id: string, _removeReason: string) => Promise<void>;
  downloadRadarSoftware: ({
    id,
    downloadFileName,
    onProgress,
    abortController,
  }: DownloadInput) => Promise<void>;
  unAssignRadarSoftware: (id: string) => Promise<void>;
  editRadar: (
    id: string,
    state: Partial<RadarDTO>,
    callback?: CallbackProp,
  ) => Promise<void>;
  downloadRadarConfigFile: (id: string) => Promise<void>;
  downloadEmptyRadarConfigFile: () => Promise<void>;
  fetchRadars: () => Promise<void>;
  getAllRadarHistory: () => Promise<RadarHistoryModel[] | void>;
  getRadarHistoryBySoftwareId: (
    id: string,
  ) => Promise<RadarHistoryModel[] | void>;
  getRadarHistory: (id: string) => Promise<RadarHistoryModel[] | void>;
  getRadarCountBySoftwareType: () => { type: string; value: number }[];
  getDeletedRadars: () => Promise<void>;
  deletedRadars: RadarModel[];
  getDeletedRadarById: (id: string) => RadarModel | undefined;
  restoreRadar: (
    id: string,
    state: RestoreRadarType,
    callback?: CallbackProp,
  ) => Promise<void>;
  deleteRadarPermanently: (id: string) => Promise<void>;
  softDeleteMultipleRadars: (
    ids: string[],
    _removeReason: string,
  ) => Promise<void>;
};

type RestoreRadarType = {
  deleted: boolean;
  deleteReason: string | null;
  deletedByUser: UserModel | null;
  deletedAt: Date | null;
};

export const RadarContext = createContext<RadarContextType | null>(null);

type Props = {
  children: ChildrenProp;
};

export const RadarsProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext) as AuthContextType;
  const navigate = useNavigate();

  // This custom hook is used to manage radars.
  const {
    array: radars,
    setArray: setRadars,
    push: pushRadar,
    update: updateRadar,
    remove: removeRadar,
  } = useArray<RadarModel>([], "_id");

  // This custom hook is used to manage DELETED radars.
  const {
    array: deletedRadars,
    setArray: setDeletedRadars,
    remove: removeDeletedRadar,
  } = useArray<RadarModel>([], "_id");

  const fetchRadars = useCallback(async () => {
    try {
      const resData = await makeReq<
        undefined,
        APIResponse<{ radars: RadarModel[] }>
      >(`/radars`);
      setRadars(resData.radars);
    } catch (err) {
      handleCatch(err as Error);
    } finally {
      setLoading(false);
    }
  }, [setRadars]);

  const getRadarById = (id: string) => {
    return radars.find((el) => el._id === id);
  };

  const getDeletedRadarById = (id: string) => {
    return deletedRadars.find((el) => el._id === id);
  };

  const filteredRadars = radars.filter((radar) => !radar.software);

  // Fetch it once user logged in
  useEffect(() => {
    if (!user) return;
    fetchRadars();
  }, [user, fetchRadars]);

  // * CRUD Operations

  const getDeletedRadars = async () => {
    try {
      const resData = await makeReq<
        undefined,
        APIResponse<{ radars: RadarModel[] }>
      >(`/radars?deleted=true`);
      setDeletedRadars(resData.radars);
    } catch (err) {
      handleCatch(err as Error);
    } finally {
      setLoading(false);
    }
  };

  const createRadar = async (val: RadarDTO, callback?: CallbackProp) => {
    try {
      const resData = await makeReq<
        RadarDTO,
        APIResponse<{ radar: RadarModel }>
      >(`/radars`, { body: val }, "POST");
      toast.success("Radar has been created successfully!");
      pushRadar(resData.radar);
      callback?.();
      await fetchRadars();
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const deleteRadar = async (id: string, _removeReason: string) => {
    try {
      await makeReq<{ removeReason: string }, APIResponse>(
        `/radars/${id}`,
        {
          body: {
            removeReason: _removeReason,
          },
        },
        "DELETE",
      );

      navigate("/dashboard/radars/list");
      toast.success("Radar has been deleted successfully!");
      removeRadar(id);
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const deleteRadarPermanently = async (id: string) => {
    try {
      await makeReq<undefined, APIResponse>(
        `/radars/${id}?permanentDelete=true`,
        {},
        "DELETE",
      );
      navigate("/dashboard/radars/deleted");
      toast.success("Radar has been deleted permanently!");
      removeDeletedRadar(id);
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const softDeleteMultipleRadars = async (
    ids: string[],
    _removeReason: string,
  ) => {
    try {
      await makeReq<{ ids: string[]; removeReason: string }, APIResponse>(
        `/radars/softdelete-multiple`,
        {
          body: {
            ids,
            removeReason: _removeReason,
          },
        },
        "PATCH",
      );
      toast.success("Radars have been deleted successfully!");

      // Remove deleted radars from radars state
      ids.forEach((id) => {
        removeRadar(id);
      });
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const downloadRadarSoftware = async ({
    id,
    downloadFileName,
    onProgress,
    abortController,
  }: DownloadInput) => {
    try {
      await makeReq<undefined, APIResponse>(
        `/radars/${id}/software`,
        { fileName: downloadFileName, onProgress },
        "GET",
        true,
        abortController, // Pass the abortController to abort the request when the user clicks the cancel button.
      );
      toast.success("Software has been downloaded successfully!");
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const unAssignRadarSoftware = async (id: string) => {
    try {
      await makeReq<undefined, APIResponse>(
        `/radars/${id}/software`,
        {},
        "DELETE",
      );
      toast.success("Software has been unassigned successfully!");
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const restoreRadar = async (
    id: string,
    state: RestoreRadarType,
    callback?: CallbackProp,
  ) => {
    try {
      const resData = await makeReq<
        RestoreRadarType,
        APIResponse<{ radar: RadarModel }>
      >(`/radars/${id}`, { body: state }, "PATCH");
      navigate("/dashboard/radars/deleted");
      toast.success("Radar has been restored successfully!");

      // remove restored radar from deletedRadars state
      removeDeletedRadar(id);

      // add restored radar to radars state
      pushRadar(resData.radar);

      callback?.();
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const editRadar = async (
    id: string,
    state: Partial<RadarDTO>,
    callback?: CallbackProp,
  ) => {
    try {
      const resData = await makeReq<
        Partial<RadarDTO>,
        APIResponse<{ radar: RadarModel }>
      >(`/radars/${id}`, { body: state }, "PATCH");
      toast.success("Radar has been updated successfully!");
      updateRadar(id, resData.radar);
      callback?.();
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const downloadRadarConfigFile = async (id: string) => {
    try {
      // Check if radar exists with the given ID
      const radar = radars.find((el) => el._id === id);
      if (!radar) {
        toast.error("Radar not found!");
        return;
      }
      if (!radar.customer) {
        toast.error(
          "Customer details not found! Download empty config file instead, on Account page",
        );
        return;
      }

      const fileName = `sd-download-config.yml`;

      // Make an async request to download the radar config file
      const resData = await makeReq<undefined, APIResponse>(
        `/radars/${id}/config`,
        { fileName },
        "GET",
        true,
      );

      if (!resData || resData.error) {
        toast.error("Error downloading the config file");
        return;
      }

      toast.success("Config file has been downloaded successfully!");

      // Use the response data as needed, e.g., downloadRadar(resData)
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const downloadEmptyRadarConfigFile = async () => {
    try {
      const fileName = `sd-download-config.yml`;

      // Make an async request to download the radar config file
      const resData = await makeReq<undefined, APIResponse>(
        `/radars/config`,
        { fileName },
        "GET",
        true,
      );

      if (!resData || resData.error) {
        toast.error("Error downloading the config file");
        return;
      }

      toast.success("Config file has been downloaded successfully!");
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const getAllRadarHistory = async () => {
    try {
      const resData = await makeReq<
        undefined,
        APIResponse<{ history: RadarHistoryModel[] }>
      >(`/radars/history`, {}, "GET");
      return resData.history;
    } catch (err) {
      handleCatch(err as Error);
      return Promise.resolve();
    }
  };

  // Get radar history by software ID, this is for recalled software
  const getRadarHistoryBySoftwareId = async (id: string) => {
    try {
      const resData = await makeReq<
        undefined,
        APIResponse<{ history: RadarHistoryModel[] }>
      >(`/radars/history/software/${id}`, {}, "GET");
      return resData.history;
    } catch (err) {
      handleCatch(err as Error);
      return Promise.resolve();
    }
  };

  // Get radar history by radar ID
  const getRadarHistory = async (id: string) => {
    try {
      const resData = await makeReq<
        undefined,
        APIResponse<{ history: RadarHistoryModel[] }>
      >(`/radars/history/${id}`, {}, "GET");
      return resData.history;
    } catch (err) {
      handleCatch(err as Error);
      return Promise.resolve();
    }
  };

  const getRadarCountBySoftwareType = () => {
    const radarCountBySoftwareType = radars.reduce(
      (acc: IndexedObject<number>, radar) => {
        if (radar.software && radar.software.softwareType) {
          const softwareType = radar.software.softwareType;
          if (!acc[softwareType]) {
            acc[softwareType] = 0;
          }
          acc[softwareType]++;
        }
        return acc;
      },
      {},
    );

    // Convert object to the desired array format
    return Object.keys(radarCountBySoftwareType).map((softwareType) => ({
      type: softwareType,
      value: radarCountBySoftwareType[softwareType],
    }));
  };

  return (
    <RadarContext.Provider
      value={{
        loading,
        radars,
        filteredRadars,
        getRadarById,
        createRadar,
        deleteRadar,
        downloadRadarSoftware,
        unAssignRadarSoftware,
        editRadar,
        downloadRadarConfigFile,
        downloadEmptyRadarConfigFile,
        fetchRadars,
        getAllRadarHistory,
        getRadarHistoryBySoftwareId,
        getRadarHistory,
        getRadarCountBySoftwareType,
        getDeletedRadars,
        deletedRadars,
        getDeletedRadarById,
        restoreRadar,
        deleteRadarPermanently,
        softDeleteMultipleRadars,
      }}
    >
      {children}
    </RadarContext.Provider>
  );
};
