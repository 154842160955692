import { Link as RouterLink, useNavigate } from "react-router-dom";
import { ChangeEvent, useContext, useState } from "react";
import { filter } from "lodash";
// material
import {
  Breadcrumbs,
  Card,
  Container,
  Link,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

// components
import Page from "@/components/Page";
import Label from "@/components/Label";
import Scrollbar from "@/components/Scrollbar";
import SearchNotFound from "@/components/SearchNotFound";
import {
  SoftwareListHead,
  SoftwareListToolbar,
} from "@/components/_dashboard/software";

//
import {
  SoftwareContext,
  SoftwareContextType,
} from "@/Contexts/SoftwareContext";
import { v4 } from "uuid";

// import format for date
import format from "date-fns/format";
import { ApplySortFilterProps, getComparator, SortOrder } from "@/utils/sort";
import { SoftwareModel } from "@/models/software";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  {
    _id: v4(),
    id: "version",
    label: "Version",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "softwareType",
    label: "Software type",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "ubuntuVersion",
    label: "Ubuntu version",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "softwarePath",
    label: "Released software path",
    alignRight: false,
  },

  {
    _id: v4(),
    id: "createdAt",
    label: "Installation date",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "recalled",
    label: "Active version",
    alignRight: false,
  },
];

// ----------------------------------------------------------------------

function applySortFilter({
  array = [],
  comparator,
  query,
}: ApplySortFilterProps<SoftwareModel>) {
  const stabilizedThis: [SoftwareModel, number][] = array.map((el, index) => [
    el,
    index,
  ]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_softwares) =>
        _softwares.version.toLowerCase().indexOf(query.toLowerCase()) !== -1,
    );
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function ReleasedSoftwares() {
  const { softwares, loading } = useContext(
    SoftwareContext,
  ) as SoftwareContextType;
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<SortOrder>("desc");
  const [orderBy, setOrderBy] = useState("createdAt");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const navigate = useNavigate();

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - (softwares?.length || 0))
      : 0;

  const filteredSoftwares = applySortFilter({
    array: softwares,
    comparator: getComparator({ order, orderBy }),
    query: filterName,
  });

  const isSoftwareNotFound = filteredSoftwares.length === 0;

  return (
    <Page title="Released softwares | Robin Radar Systems Admin">
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to="/" underline="hover" color="inherit">
          Home
        </Link>
        <Typography color="text.primary">Released softwares</Typography>
      </Breadcrumbs>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Released softwares
          </Typography>
        </Stack>

        <Card>
          <SoftwareListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            slug="for version..."
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <SoftwareListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={softwares?.length || 0}
                  onRequestSort={handleRequestSort}
                  noCheckBox
                />
                <TableBody>
                  {loading
                    ? Array(5)
                        .fill(null)
                        .map(() => {
                          return (
                            <TableRow
                              hover
                              key={v4()}
                              tabIndex={-1}
                              role="checkbox"
                              sx={{
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                            >
                              {Array(9)
                                .fill(null)
                                .map(() => (
                                  <TableCell key={v4()} align="right">
                                    <Skeleton />
                                  </TableCell>
                                ))}
                            </TableRow>
                          );
                        })
                    : filteredSoftwares

                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((row) => {
                          // ROW variables
                          const {
                            _id,
                            version,
                            softwarePath,
                            recalled,
                            softwareType,
                            // createdAt,
                            ubuntuVersion,
                          } = row;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              sx={{
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                              onClick={() =>
                                navigate(`/dashboard/softwares/${row._id}`)
                              }
                            >
                              <TableCell padding="checkbox"></TableCell>
                              <TableCell align="left">{version}</TableCell>
                              <TableCell align="left">{softwareType}</TableCell>
                              <TableCell align="left">
                                {ubuntuVersion?.length
                                  ? ubuntuVersion.join(", ")
                                  : " - "}
                              </TableCell>
                              <TableCell align="left">
                                {softwarePath && softwarePath.length > 0 ? (
                                  <Tooltip
                                    title={softwarePath}
                                    placement="top-start"
                                    arrow
                                  >
                                    <span>
                                      {softwarePath.length > 15
                                        ? "..." + softwarePath.slice(-15)
                                        : softwarePath}
                                    </span>
                                  </Tooltip>
                                ) : (
                                  "Not found."
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {format(
                                  new Date(row.createdAt),
                                  "yyyy-MM-dd, HH:mm:ss",
                                ) || " - "}
                              </TableCell>
                              <TableCell align="left">
                                <Label color={recalled ? "error" : "success"}>
                                  {recalled ? "No" : "Yes"}
                                </Label>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isSoftwareNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={TABLE_HEAD.length + 1}
                        sx={{ py: 3 }}
                      >
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={softwares?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
