import React from "react";
import { LinearProgress, Typography } from "@mui/material";
import { DownloadStatusType } from "@/utils/types";

type Props = {
  downloadStatus: DownloadStatusType | null;
  downloadProgress: number;
};

const DownloadProgress = ({ downloadStatus, downloadProgress }: Props) => {
  if (downloadStatus !== "downloading") return null;

  return (
    <>
      <LinearProgress
        variant="determinate"
        value={downloadProgress}
        sx={{ mt: 2 }}
      />
      <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
        Download progress: {downloadProgress.toFixed(1)}%
      </Typography>
    </>
  );
};

export default DownloadProgress;
