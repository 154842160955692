import { replace } from "lodash";
import numeral from "numeral";

// ----------------------------------------------------------------------

export type FormatNumber = number | string;

export function fCurrency(input: FormatNumber) {
  return numeral(input).format(Number.isInteger(input) ? "$0,0" : "$0,0.00");
}

export function fPercent(input: FormatNumber) {
  if (typeof input === "string") input = parseFloat(input);
  return numeral(input / 100).format("0.0%");
}

export function fNumber(input: FormatNumber) {
  return numeral(input).format();
}

export function fShortenNumber(input: FormatNumber) {
  return replace(numeral(input).format("0.00a"), ".00", "");
}

export function fData(input: FormatNumber) {
  return numeral(input).format("0.0 b");
}
