import React, { createContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { handleCatch, makeReq } from "@/utils/makeReq";
import { ChildrenProp } from "@/utils/types";
import { UserModel } from "@/models/user";
import { APIResponse } from "@/Contexts/types";

export const LOCALSTORAGE_TOKEN_KEY = "robin-admin-token";

type GoogleAuth = { credential: string };

export type AuthContextType = {
  loading: boolean;
  token: string | null;
  setToken: (token: string | null) => void;
  logoutUser: () => void;
  user: UserModel | null;
  setUser: (user: UserModel | null) => void;
  signInUser: (token: string, user: UserModel) => void;
  updateMe: (
    newProfile: Partial<UserModel>,
    setState: (user: UserModel | null) => void,
  ) => Promise<void>;
  changeMyPassword: (updatedPassword: string) => Promise<void>;
  changeUserPassword: (
    updatedPassword: string,
    userId: string,
  ) => Promise<void>;
  handleGoogle: (response: GoogleAuth) => Promise<void>;
};

export const AuthContext = createContext<AuthContextType | null>(null);

type Props = {
  children: ChildrenProp;
};

export const AuthProvider = ({ children }: Props) => {
  const [token, setToken] = useState<string | null>(null);
  const [user, setUser] = useState<UserModel | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    try {
      const tokenLocal = window.localStorage.getItem(LOCALSTORAGE_TOKEN_KEY);
      setToken(tokenLocal);
      getMe();
    } catch (err) {
      console.error(err);
    }
  }, []);

  const getMe = async () => {
    try {
      const res = await makeReq<undefined, APIResponse<{ user: UserModel }>>(
        `/users/me`,
        {},
        "GET",
      );
      setUser(res.user);
      setLoading(false);
    } catch (err) {
      setToken(null);
      localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
      localStorage.removeItem("user");
    }
  };

  const updateMe = async (
    newProfile: Partial<UserModel>,
    setState: (user: UserModel | null) => void,
  ) => {
    try {
      const res = await makeReq<
        Partial<UserModel>,
        APIResponse<{ user: UserModel }>
      >(`/users/me`, { body: newProfile }, "PATCH");
      setUser(res.user);
      setLoading(false);
      toast.success("Profile has been updated Successfully !");
    } catch (err) {
      setState(user);
      handleCatch(err as Error);
    }
  };

  const signInUser = (token: string, user: UserModel) => {
    window.localStorage.setItem(LOCALSTORAGE_TOKEN_KEY, token);
    setTimeout(() => {
      setToken(token);
      setUser(user);
      setLoading(false);
    }, 1000);

    toast.success("Logged in Successfully !");
  };

  const logoutUser = () => {
    setToken(null);
    setUser(null);
    setLoading(false);

    localStorage.removeItem("user");
    localStorage.removeItem(LOCALSTORAGE_TOKEN_KEY);
  };

  // Update password
  const changeMyPassword = async (updatedPassword: string) => {
    try {
      const resData = await makeReq<
        { password: string },
        APIResponse<{ user: UserModel }>
      >(
        `/users/updatepassword/${user?._id}`,
        { body: { password: updatedPassword } },
        "PATCH",
      );
      toast.success("Password Updated Successfully !");
      setUser(resData.user);
      setLoading(false);
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  // update user password
  const changeUserPassword = async (
    updatedPassword: string,
    userId: string,
  ) => {
    try {
      await makeReq<{ password: string }, APIResponse>(
        `/users/updateuserpassword/${userId}`,
        { body: { password: updatedPassword } },
        "PATCH",
      );
      toast.success("Password Updated Successfully !");
      // no need set user as it is not logged-in user!
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const handleGoogle = async (response: GoogleAuth) => {
    try {
      const res = await makeReq<
        { idToken: string },
        APIResponse<{ token: string; user: UserModel }>
      >(
        `/auth/googlelogin`,
        {
          body: { idToken: response.credential },
        },
        "POST",
      );
      signInUser(res.token, res.user);
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        loading,
        token,
        setToken,
        logoutUser,
        user,
        setUser,
        signInUser,
        updateMe,
        changeMyPassword,
        changeUserPassword,
        handleGoogle,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
