import { useArray } from "@/hooks";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { handleCatch, makeReq } from "@/utils/makeReq";
import { AuthContext, AuthContextType } from "@/Contexts/AuthContext";
import { ChildrenProp } from "@/utils/types";
import { LogModel } from "@/models/log";
import { APIResponse } from "@/Contexts/types";

export type LogContextType = {
  loading: boolean;
  logs: LogModel[];
  getLogById: (id: string) => LogModel | undefined;
  deleteAllLogs: () => Promise<void>;
};

export const LogContext = createContext<LogContextType | null>(null);

type Props = {
  children: ChildrenProp;
};

export const LogsProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext) as AuthContextType;
  const {
    array: logs,
    setArray: setLogs,
    clear: clearLogs,
  } = useArray<LogModel>([], "_id");

  const fetchLogs = useCallback(async () => {
    try {
      const resData = await makeReq<
        undefined,
        APIResponse<{ logs: LogModel[] }>
      >(`/logs`);
      setLogs(resData.logs);
    } catch (err) {
      handleCatch(err as Error);
    } finally {
      setLoading(false);
    }
  }, [setLogs]);

  const getLogById = (id: string) => {
    return logs.find((el) => el._id === id);
  };

  useEffect(() => {
    if (!user) return;
    fetchLogs();
  }, [fetchLogs, user]);

  // * CRUD Operations
  const deleteAllLogs = async () => {
    try {
      await makeReq<undefined, APIResponse>(`/logs`, {}, "DELETE");
      toast.success("All logs have been deleted successfully!");
      clearLogs();
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  return (
    <LogContext.Provider
      value={{
        loading,
        logs,
        getLogById,
        deleteAllLogs,
      }}
    >
      {children}
    </LogContext.Provider>
  );
};
