import * as React from "react";

// material
import {
  Button,
  Stack,
  Container,
  Typography,
  Card,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Tooltip,
  SelectChangeEvent,
} from "@mui/material";

import {
  useContext,
  useState,
  useEffect,
  ChangeEvent,
  MouseEvent,
} from "react";

import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

// components
import Page from "@/components/Page";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Iconify from "@/components/Iconify";
import SyncLockIcon from "@mui/icons-material/SyncLock";
import { UserContext, UserContextType } from "@/Contexts/UserContext";
import { toast } from "react-toastify";

// breadcrumbs
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";

// Utils
import { generatePassword } from "@/utils/generatePassword";
import { UserDTO, UserRole } from "@/models/user";

export default function NewUser() {
  // Context
  const { createUser } = useContext(UserContext) as UserContextType;

  // Generate password function, 15-20 chars default if no length is passed
  const [password, setPassword] = useState("");
  function handleGeneratePassword() {
    const password = generatePassword();
    setPassword(password);
    setValues({ ...values, password });
  }

  useEffect(() => {
    if (password) {
      const input = document.getElementById(
        "outlined-adornment-password",
      ) as HTMLInputElement; // select the password input field
      input.value = password; // copy the generated password to the input field
    }
  }, [password]);

  // State for user fields
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [role, setRole] = useState<UserRole>("customer");
  const [activated] = useState(true); // (As I implemented verification, it could be connected to that)
  const [isTestUser, setIsTestUser] = useState(false);

  //for password field functionality
  const [values, setValues] = useState({
    password: "",
    showPassword: false,
  });

  //for checkbox functionality
  const [checked, setChecked] = useState(false);
  const handleCheckBoxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const handleChange =
    (prop: string) => (event: ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  // keep all other fields updated
  const changeName = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const changeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const changePhone = (event: ChangeEvent<HTMLInputElement>) => {
    setPhone(event.target.value);
  };

  const handleTestCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsTestUser(event.target.checked);
  };

  // handle dropdown change for roles
  const changeRole = (event: SelectChangeEvent) => {
    setRole(event.target.value as UserRole);
  };

  // transfer values from other fields to REQUEST
  const transferValue = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const val: UserDTO = {
      name,
      email,
      phone,
      role,
      password: values.password,
      activated,
      testing: isTestUser,
    };
    const response = await createUser(val);
    if (checked) {
      await navigator.clipboard.writeText(values.password);
    }

    try {
      if (response && response.status === "success") {
        clearState();
      }
    } catch (error) {
      toast.error("Response is not success, please check your fields!");
    }
  };

  // clear state after a request has been sent
  const clearState = () => {
    setName("");
    setEmail("");
    setPhone("");
    setRole("customer");
    setValues({
      ...values,
      password: "",
    });
    setIsTestUser(false);
  };

  return (
    <Page title="New User | Robin Radar Systems Admin">
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to="/" underline="hover" color="inherit">
          Home
        </Link>
        <Typography color="text.primary">New User</Typography>
      </Breadcrumbs>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Create new user
          </Typography>
        </Stack>
        <Card
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            width: "80%",
            minWidth: "550px",
          }}
        >
          <Container>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              mt={2}
              gap="10px"
            >
              <TextField
                required
                error={name === ""}
                id="fullname"
                label="Full Name"
                variant="outlined"
                value={name}
                onChange={changeName}
                autoComplete="off"
              />

              <TextField
                required
                error={email === ""}
                id="email"
                label="Email Address"
                variant="outlined"
                value={email}
                onChange={changeEmail}
                autoComplete="off"
                sx={{ minWidth: 350 }}
              />
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              mt={2}
              gap="10px"
            >
              <TextField
                id="phone"
                label="Phone Number"
                variant="outlined"
                value={phone}
                onChange={changePhone}
                autoComplete="off"
              />

              {/* Select list for Role */}
              <FormControl sx={{ minWidth: 350 }}>
                <InputLabel id="roleid">Role</InputLabel>
                <Select
                  labelId="selectrole-label"
                  id="selectrole"
                  value={role}
                  label="Role"
                  onChange={changeRole}
                >
                  <MenuItem value={"customer"}>Customer</MenuItem>
                  <MenuItem value={"admin"}>Admin</MenuItem>
                  <MenuItem value={"S&S"}>S&S</MenuItem>
                  <MenuItem value={"R&D"}>R&D</MenuItem>
                </Select>
              </FormControl>
            </Stack>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              mb={2}
              mt={2}
              gap="10px"
            >
              <FormControl
                sx={{ width: "225px" }}
                variant="outlined"
                required
                error={values.password === ""}
              >
                <InputLabel htmlFor="outlined-adornment-password">
                  Password
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-password"
                  type={values.showPassword ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {values.showPassword ? (
                          <Visibility />
                        ) : (
                          <VisibilityOff />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                  label="Password"
                />
              </FormControl>
              {/* ToolTip for generate password button */}
              <Tooltip
                title="Generate a random password"
                aria-label="generate a random password"
              >
                {/* create a button with SyncLockIcon icon */}
                <IconButton
                  aria-label="generate a random password"
                  onClick={handleGeneratePassword}
                  size="medium"
                  sx={{
                    color: "white",
                    bgcolor: "primary.main",
                    ":hover": { bgcolor: "primary.dark" },
                  }}
                >
                  <SyncLockIcon />
                </IconButton>
              </Tooltip>
            </Stack>

            {/* Stack, so 2 column */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              mb={2}
              mt={2}
              gap="10px"
            >
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checked}
                      onChange={handleCheckBoxChange}
                    />
                  }
                  label="Copy password to clipboard"
                />
              </FormGroup>

              <Tooltip title="Toggle this checkbox if this user is meant for testing purposes. It will not be visible in the main user list, unless you select the correct option in the filter.">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isTestUser}
                      onChange={handleTestCheckboxChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Test user"
                />
              </Tooltip>
            </Stack>
            {/* Create user button */}
            <Stack
              direction="row"
              alignItems="left"
              justifyContent="right"
              mt={2}
              gap="10px"
            >
              <Button
                sx={{ textTransform: "none" }}
                variant="contained"
                onClick={transferValue}
                startIcon={<Iconify icon="eva:plus-fill" />}
                disabled={!name || !email || !values.password}
              >
                New user
              </Button>
            </Stack>
          </Container>
        </Card>
      </Container>
    </Page>
  );
}
