import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { Form, FormikProvider, useFormik } from "formik";
import { Icon } from "@iconify/react";
import eyeFill from "@iconify/icons-eva/eye-fill";
import eyeOffFill from "@iconify/icons-eva/eye-off-fill";
// material
import {
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  Tooltip,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";

import { API_BASE_URL, handleCatch } from "@/utils/makeReq";
import { AuthContext, AuthContextType } from "@/Contexts/AuthContext";
import axios from "axios";

// ----------------------------------------------------------------------

export function LoginForm() {
  const { handleGoogle, signInUser } = useContext(
    AuthContext,
  ) as AuthContextType;
  const [showPassword, setShowPassword] = useState(false);
  const GOOGLE_CLIENT_ID =
    "1054745607072-g0vk7qbvkbu0ct4u0k3pgt956vudqil3.apps.googleusercontent.com";

  useEffect(() => {
    // global google
    const google = (window as any).google;
    if (google) {
      google.accounts.id.initialize({
        client_id: GOOGLE_CLIENT_ID,
        callback: handleGoogle,
      });

      google.accounts.id.renderButton(document.getElementById("loginDiv"), {
        type: "standard",
        theme: "filled_black",
        size: "large",
        text: "signin_with",
        shape: "rectangular",
        locale: "en",
      });

      // google.accounts.id.prompt();
    }
  }, [handleGoogle]);

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Email must be a valid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginSchema,
    onSubmit: async () => {
      try {
        const res = await axios.post(`${API_BASE_URL}/auth/login`, {
          ...values,
        });
        if (
          res.data.user.role !== "admin" &&
          res.data.user.role !== "S&S" &&
          res.data.user.role !== "R&D"
        ) {
          toast.error("You do not have access to this page");
          return;
        }

        signInUser(res.data.token, res.data.user);
      } catch (err) {
        console.log(`ERR LOGIN`, err);
        handleCatch(err as Error);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } =
    formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email address"
            {...getFieldProps("email")}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            autoComplete="current-password"
            type={showPassword ? "text" : "password"}
            label="Password"
            {...getFieldProps("password")}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
          spacing={3}
        >
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={isSubmitting}
          >
            Login
          </LoadingButton>
          <>
            <Tooltip
              title="Only works with @robinradar.com email"
              placement="bottom"
              arrow
              enterDelay={1500}
            >
              <div id="loginDiv"></div>
            </Tooltip>
          </>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
