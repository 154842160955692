import {
  Button,
  Card,
  Container,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import NotFound from "@/pages/Page404";
import Page from "@/components/Page";
import { UserContext, UserContextType } from "@/Contexts/UserContext";
import { toast } from "react-toastify";
import Iconify from "@/components/Iconify"; // breadcrumb
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs"; // format date
import { add, format } from "date-fns";
import AlertDialog from "@/components/dialogs/AlertDialog";
import { UserModel } from "@/models/user";

export default function DeletedUserDetails() {
  // Context
  const {
    deletedUsers,
    getDeletedUserById,
    restoreUser,
    deleteUserPermanently,
    loading,
  } = useContext(UserContext) as UserContextType;

  const { id } = useParams();

  const [user, setUser] = useState<UserModel>();
  const [userNotFound, setUserNotFound] = useState(false);

  // State for dialogs
  const [openRestoreUserDialog, setOpenRestoreUserDialog] = useState(false);
  const [openRemoveUserDialog, setOpenRemoveUserDialog] = useState(false);

  //
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    if (loading) return;

    let el = getDeletedUserById(id);

    if (!el) {
      setUserNotFound(true);
      toast.error("The user with this id doesn't exist.");
    } else {
      setUser(el);
    }
  }, [getDeletedUserById, deletedUsers, loading, id]);

  // Handle functions
  const handleRemoveUser = async () => {
    if (user) {
      navigate("/dashboard/user/deleted");
      await deleteUserPermanently(user?._id);
      setOpenRemoveUserDialog(false);
    }
  };

  const handleRestoreUser = async () => {
    if (user) {
      navigate("/dashboard/user/deleted");
      await restoreUser(user?._id);
      setOpenRestoreUserDialog(false);
    }
  };

  return (
    <>
      {userNotFound || loading ? (
        <Page title="404 | Not Found">
          {loading ? (
            <Skeleton variant="rectangular" height="600px" />
          ) : (
            <NotFound />
          )}
        </Page>
      ) : (
        <Page title={user?.name + " | Robin Radar Systems Admin"}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              component={RouterLink}
              to="/"
              underline="hover"
              color="inherit"
            >
              Home
            </Link>
            <Link
              component={RouterLink}
              to="/dashboard/user/deleted"
              underline="hover"
              color="inherit"
            >
              Deleted Users
            </Link>
            <Typography color="text.primary">{user?.name}</Typography>
          </Breadcrumbs>
          <Container>
            <Grid justifyContent="flex-start" alignItems="flex-start">
              <Grid item style={{ marginBottom: "1rem" }}>
                <Typography variant="h4" gutterBottom>
                  {user?.name}
                </Typography>
                <Card
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    maxHeight: "32.5rem",
                    minWidth: "60vw",
                  }}
                >
                  {/* Start of the two-column layout inside the card */}
                  <Grid container spacing={3}>
                    {/* Left Column (Main Radar Details) */}
                    <Grid item xs={8}>
                      <Stack
                        direction="column"
                        alignItems="left"
                        justifyContent="left"
                      >
                        <Typography variant="body1" gutterBottom>
                          <b>User ID: </b> {user?._id}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <b>Email: </b> {user?.email}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <b> Phone: </b>
                          {user?.phone ? user?.phone : "not available"}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <b>Role: </b>
                          {user?.role}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <b>Activated: </b>
                          {user?.activated === false ? " no." : " yes."}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <b>Test user: </b>
                          {user?.testing === false ? " no." : " yes."}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <b>Registration: </b>
                          {user?.createdAt
                            ? format(
                                new Date(user.createdAt),
                                "yyyy-MM-dd, HH:mm:ss",
                              )
                            : "N/A"}
                        </Typography>
                      </Stack>
                    </Grid>

                    {/* Right Column (Deleted Related Info) */}
                    <Grid item xs={4}>
                      <Stack
                        direction="column"
                        alignItems="flex-start"
                        justifyContent="space-around"
                        sx={{
                          backgroundColor: "#f8d7da",
                          padding: "1rem",
                          borderRadius: "0.5rem",
                        }}
                      >
                        <Typography variant="body1" gutterBottom>
                          <b>Deleted at: </b>
                          {user?.deletedAt
                            ? format(
                                new Date(user.deletedAt),
                                "yyyy-MM-dd, HH:mm:ss",
                              )
                            : "N/A"}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <b>Delete reason: </b>
                          {user?.deleteReason || "N/A"}
                        </Typography>
                        <Typography variant="body1" gutterBottom>
                          <b>Removal date (Delete at +30 days): </b>
                          {user?.deletedAt
                            ? format(
                                add(new Date(user.deletedAt), { days: 30 }),
                                "yyyy-MM-dd",
                              )
                            : "N/A"}
                        </Typography>
                        {/* <Typography variant='body1' gutterBottom>
                          <b>Deleted by: </b>
                          {user?.deletedBy || 'N/A'}
                        </Typography> */}
                      </Stack>
                    </Grid>
                  </Grid>
                  <Stack direction="row" justifyContent="space-between" mt={2}>
                    <Button
                      startIcon={<Iconify icon="ion:arrow-undo" />}
                      color="primary"
                      onClick={() => setOpenRestoreUserDialog(true)}
                      variant="contained"
                    >
                      Restore this user
                    </Button>
                    <Button
                      startIcon={<Iconify icon="bx:bx-trash" />}
                      variant="contained"
                      color="error"
                      onClick={() => setOpenRemoveUserDialog(true)}
                    >
                      Permanently remove this user
                    </Button>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Container>
          {openRestoreUserDialog && (
            <AlertDialog
              onConfirm={handleRestoreUser}
              onCancel={() => setOpenRestoreUserDialog(false)}
              title={`Are you sure you want to restore ${user?.name}?`}
              description={
                "This action will restore this user. The user will be able to login again, but after restoring it, you need to activate it manually."
              }
            />
          )}
          {openRemoveUserDialog && (
            <AlertDialog
              onConfirm={handleRemoveUser}
              onCancel={() => setOpenRemoveUserDialog(false)}
              title={`Are you sure you want to permanently delete ${user?.name}?`}
              description={
                "This action will delete permanently this user from the database, and every data related to it. This action cannot be undone."
              }
            />
          )}
        </Page>
      )}
    </>
  );
}
