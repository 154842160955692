import { filter } from "lodash";
import {
  ChangeEvent,
  SyntheticEvent,
  useContext,
  useEffect,
  useState,
} from "react";
// breadcrumbs
//
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";

// material
import {
  Card,
  Checkbox,
  Container,
  IconButton,
  MenuItem,
  Popover,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

// components
import Page from "@/components/Page";
import Scrollbar from "@/components/Scrollbar";
import SearchNotFound from "@/components/SearchNotFound";
import { RadarListHead, RadarListToolbar } from "@/components/_dashboard/radar";
import Iconify from "@/components/Iconify";
import AlertDialog from "@/components/dialogs/AlertDialog";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog";
import Link from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { RadarContext, RadarContextType } from "@/Contexts/RadarContext";
import {
  SoftwareContext,
  SoftwareContextType,
} from "@/Contexts/SoftwareContext";
import { v4 } from "uuid";

// import for displaying date
import { format } from "date-fns";
import { ApplySortFilterProps, getComparator, SortOrder } from "@/utils/sort";
import { RadarModel } from "@/models/radar";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { _id: v4(), id: "checkbox", label: "", alignRight: false },
  {
    _id: v4(),
    id: "alias",
    label: "Radar alias",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "customer.name",
    label: "Customer",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "software.softwareType",
    label: "Software type",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "software.ubuntuVersion",
    label: "Ubuntu version",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "software.version",
    label: "Software version",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "notes",
    label: "Notes",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "createdAt",
    label: "Created at",
    alignRight: false,
  },
  {
    _id: v4(),
    id: "createdByUser.name",
    label: "Created by",
    alignRight: false,
  },
];

// ----------------------------------------------------------------------

export type DDFilter =
  | "hide-test"
  | "show-test"
  | "show-all"
  | "show-no-software";

function applySortFilter({
  array = [],
  comparator,
  query,
  selectedDropdownFilter,
}: ApplySortFilterProps<RadarModel> & { selectedDropdownFilter: DDFilter }) {
  const stabilizedThis: [RadarModel, number][] = array.map((el, index) => [
    el,
    index,
  ]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  let result = stabilizedThis.map((el) => el[0]);

  if (query) {
    result = filter(
      result,
      (_radars) =>
        (!!_radars._id &&
          _radars._id.toLowerCase().indexOf(query.toLowerCase()) !== -1) ||
        (!!_radars.customer?.name &&
          _radars.customer.name.toLowerCase().indexOf(query.toLowerCase()) !==
            -1),
    );
  }

  if (selectedDropdownFilter === "hide-test") {
    result = filter(result, (_radars) => !_radars.testing);
  } else if (selectedDropdownFilter === "show-test") {
    result = filter(result, (_radars) => _radars.testing);
  } else if (selectedDropdownFilter === "show-no-software") {
    result = filter(result, (_radars) => !_radars.software);
  }

  return result;
}

export default function Radars() {
  // Get the filter from the URL
  const location = useLocation();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get("filter") === "no-software") {
      setSelectedDropdownFilter("show-no-software");
    }
  }, [location.search]);

  // Contexts
  // (filter out the deleted radars from the list of all radars, could be done in the context API call as well)
  const {
    radars: allRadars,
    deleteRadar,
    editRadar,
    loading,
    softDeleteMultipleRadars,
  } = useContext(RadarContext) as RadarContextType;
  const radars = allRadars.filter((radar) => !radar.deleted);
  const { softwares } = useContext(SoftwareContext) as SoftwareContextType;

  // States
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState<SortOrder>("desc");
  const [selected, setSelected] = useState<string[]>([]);
  const [orderBy, setOrderBy] = useState("createdAt");
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState<(EventTarget & Element) | null>(null);
  const [selectedRadar, setSelectedRadar] = useState<RadarModel | null>(null);
  const [radarIdentifier, setRadarIdentifier] = useState("");

  // States for alert dialogs
  const [openRadarMarkTestDialog, setOpenRadarMarkTestDialog] = useState(false);
  const [openRadarDeleteDialog, setOpenRadarDeleteDialog] = useState(false);
  const [
    openDeleteAllSelectedRadarDialog,
    setOpenDeleteAllSelectedRadarDialog,
  ] = useState(false);

  // Dropdown filter
  const [selectedDropdownFilter, setSelectedDropdownFilter] =
    useState<DDFilter>("hide-test");

  const navigate = useNavigate();

  const handleOpenMenu = (event: SyntheticEvent, row: RadarModel) => {
    event.stopPropagation();
    setSelectedRadar(row);
    setOpen(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
    setSelectedRadar(null);
  };

  // handle functions for alert dialogs
  const handleMarkTestRadar = async (event: SyntheticEvent) => {
    if (selectedRadar) {
      event.stopPropagation();
      selectedRadar.testing = !selectedRadar.testing;
      await editRadar(selectedRadar._id, selectedRadar);
      setOpenRadarMarkTestDialog(false);
      handleCloseMenu();
    }
  };

  const handleDeleteRadar = async (inputText: string) => {
    if (selectedRadar) {
      await deleteRadar(selectedRadar._id, inputText);
      setOpenRadarDeleteDialog(false);
      handleCloseMenu();
    }
  };

  const handleEditRadar = (event: SyntheticEvent) => {
    if (selectedRadar) {
      event.stopPropagation();
      navigate(`/dashboard/radars/${selectedRadar._id}/edit`);
      handleCloseMenu();
    }
  };

  const handleSelectedTrashClick = async (inputText: string) => {
    await softDeleteMultipleRadars(selected, inputText);
    setOpenDeleteAllSelectedRadarDialog(false);
    setSelected([]);
  };

  const handleRequestSort = (property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = () => {
    if (selected.length === filteredRadars.length) {
      setSelected([]);
    } else {
      setSelected(filteredRadars.map((radar) => radar._id));
    }
  };

  const handleClick = (_id: string) => {
    const selectedIndex = selected.indexOf(_id);

    if (selectedIndex === -1) {
      // If not currently selected, add to the selection.
      setSelected((prevSelected) => [...prevSelected, _id]);
    } else {
      // If currently selected, remove from the selection.
      setSelected((prevSelected) => prevSelected.filter((id) => id !== _id));
    }
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleDropdownFilterChange = (event: SelectChangeEvent) => {
    setPage(0);
    setSelectedDropdownFilter(event.target.value as DDFilter);
  };

  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - (radars?.length || 0))
      : 0;

  const filteredRadars = applySortFilter({
    array: radars,
    comparator: getComparator({ order, orderBy }),
    query: filterName,
    selectedDropdownFilter,
  });

  const isRadarNotFound = filteredRadars.length === 0;

  // Set the radar identifier for the alert dialogs, based on the selected radar
  useEffect(() => {
    if (selectedRadar) {
      const identifier = selectedRadar.alias
        ? selectedRadar.alias
        : selectedRadar._id;
      setRadarIdentifier(identifier);
    } else {
      setRadarIdentifier(""); // reset to default if selectedRadar is null or undefined
    }
  }, [selectedRadar]);

  return (
    <Page title="Radars | Robin Radar Systems Admin">
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to="/" underline="hover" color="inherit">
          Home
        </Link>
        <Typography color="text.primary">Radars</Typography>
      </Breadcrumbs>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Radars
          </Typography>
        </Stack>

        <Card sx={{ width: "110%" }}>
          <RadarListToolbar
            numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            slug="for customer or radar ID..."
            selectedDropdownFilter={selectedDropdownFilter}
            handleDropdownFilterChange={handleDropdownFilterChange}
            navigate={navigate}
            onTrashClick={() => setOpenDeleteAllSelectedRadarDialog(true)}
            showDropdownFilter={true}
            showCreateButton={true}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <RadarListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={radars?.length || 0}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {loading
                    ? Array(5)
                        .fill(null)
                        .map(() => {
                          return (
                            <TableRow
                              hover
                              key={v4()}
                              tabIndex={-1}
                              role="checkbox"
                              sx={{
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                            >
                              {Array(9)
                                .fill(null)
                                .map(() => (
                                  <TableCell key={v4()} align="right">
                                    <Skeleton />
                                  </TableCell>
                                ))}
                            </TableRow>
                          );
                        })
                    : filteredRadars

                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage,
                        )
                        .map((row) => {
                          const {
                            _id,
                            alias,
                            customer,
                            software,
                            notes,
                            createdAt,
                            createdByUser,
                          } = row;

                          const isItemSelected = selected.indexOf(_id) !== -1;

                          return (
                            <TableRow
                              hover
                              key={_id}
                              tabIndex={-1}
                              role="checkbox"
                              selected={isItemSelected}
                              aria-checked={isItemSelected}
                              sx={{
                                cursor: "pointer",
                                textDecoration: "none",
                              }}
                              onClick={() =>
                                navigate(`/dashboard/radars/${row._id}`, {
                                  state: softwares,
                                })
                              }
                            >
                              <TableCell
                                padding="checkbox"
                                onClick={(event) => {
                                  event.stopPropagation(); // prevent the event from reaching the TableRow
                                }}
                                sx={{
                                  cursor: "default",
                                  textDecoration: "none",
                                }}
                              >
                                <Checkbox
                                  checked={selected.includes(_id)} // This checks if the user's _id is in the selected array
                                  onClick={(event) => {
                                    event.stopPropagation(); // Prevent event from reaching the TableRow
                                    handleClick(_id);
                                  }}
                                />
                              </TableCell>

                              <TableCell padding="checkbox"></TableCell>
                              <TableCell align="left">
                                <Tooltip
                                  title={
                                    (alias && alias.length > 0
                                      ? alias
                                      : "N/A") +
                                    " (Radar ID: " +
                                    _id +
                                    ")"
                                  }
                                  placement="top-start"
                                  arrow
                                >
                                  <span>
                                    {alias && alias.length > 0
                                      ? alias.length > 20
                                        ? alias.slice(0, 20) + "..."
                                        : alias
                                      : "N/A"}
                                  </span>
                                </Tooltip>
                              </TableCell>
                              <TableCell align="left">
                                {customer?.name === null
                                  ? "N/A."
                                  : customer?.name}
                              </TableCell>
                              <TableCell align="left">
                                {software?.softwareType === null
                                  ? "N/A."
                                  : software?.softwareType}
                              </TableCell>
                              <TableCell align="left">
                                {software?.ubuntuVersion?.length
                                  ? software?.ubuntuVersion.join(", ")
                                  : " N/A. "}
                              </TableCell>
                              <TableCell align="left">
                                {software?.version === null
                                  ? "N/A."
                                  : software?.version}
                              </TableCell>
                              <TableCell align="left">
                                {notes && notes.length > 0 ? (
                                  <Tooltip
                                    title={notes}
                                    placement="top-start"
                                    arrow
                                  >
                                    <span>
                                      {notes.length > 20
                                        ? notes.slice(0, 20) + "..."
                                        : notes}
                                    </span>
                                  </Tooltip>
                                ) : (
                                  "N/A."
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {format(
                                  new Date(createdAt),
                                  "yyyy-MM-dd, HH:mm:ss",
                                )}
                              </TableCell>
                              <TableCell align="left">
                                {createdByUser?.name}
                              </TableCell>
                              <TableCell
                                sx={{
                                  cursor: "default",
                                  textDecoration: "none",
                                }}
                                align="right"
                                onClick={(event) => {
                                  event.stopPropagation();
                                }}
                              >
                                <IconButton
                                  size="small"
                                  color="inherit"
                                  onClick={(event) => {
                                    handleOpenMenu(event, row);
                                  }}
                                >
                                  <Iconify icon={"eva:more-vertical-fill"} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
                {isRadarNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={TABLE_HEAD.length}
                        sx={{ py: 3 }}
                      >
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={radars?.length || 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={(_, newPage) => setPage(newPage)}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: "top", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            p: 1,
            width: 220,
            "& .MuiMenuItem-root": {
              px: 1,
              typography: "body2",
              borderRadius: 0.75,
            },
          },
        }}
      >
        <MenuItem onClick={handleEditRadar}>
          <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
          Modify
        </MenuItem>
        <MenuItem onClick={() => setOpenRadarMarkTestDialog(true)}>
          <Iconify icon={"eva:checkmark-circle-2-fill"} sx={{ mr: 2 }} />
          {selectedRadar?.testing
            ? "Mark as NOT test radar"
            : "Mark as TEST radar"}
        </MenuItem>
        <MenuItem
          onClick={() => setOpenRadarDeleteDialog(true)}
          sx={{ color: "error.main" }}
        >
          <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
          Delete
        </MenuItem>
      </Popover>

      {openRadarMarkTestDialog && (
        <AlertDialog
          title={
            <>
              Are you sure you want to mark{" "}
              <span style={{ color: "red" }}>{radarIdentifier}</span>
              {selectedRadar?.testing ? " as NON-TEST?" : " as TEST?"}
            </>
          }
          description={
            <>
              This action will change the radar identified by{" "}
              <span style={{ color: "red" }}>{radarIdentifier}</span> to{" "}
              {selectedRadar?.testing ? "non-test" : "test"} status. The radar
              will be moved to the
              {selectedRadar?.testing ? " main" : " test"} radars list. So
              please select the correct option from the dropdown menu to filter
              the list, if you want to see the radar after this action.
            </>
          }
          onConfirm={handleMarkTestRadar}
          onCancel={() => setOpenRadarMarkTestDialog(false)}
        />
      )}

      {openRadarDeleteDialog && (
        <ConfirmationDialog
          title={
            <>
              Are you sure you want to delete{" "}
              <span style={{ color: "red" }}>{radarIdentifier}</span>
              {selectedRadar?.testing ? " radar (test)" : " radar (non-test)"}?
            </>
          }
          onConfirm={(inputText) => handleDeleteRadar(inputText)}
          onCancel={() => setOpenRadarDeleteDialog(false)}
          description={
            <>
              This action will delete the radar identified by{" "}
              <span style={{ color: "red" }}>{radarIdentifier}</span>{" "}
              {selectedRadar?.testing ? "(test)" : "(non-test)"} from the
              system. The radar will be permanently deleted after a period of
              time. Please note that, the deleted radar cannot be used for any
              purpose You can only delete a radar created by you. If you want to
              delete a radar created by another user, please contact someone
              (admin) who has the permission to do.
            </>
          }
        />
      )}

      {openDeleteAllSelectedRadarDialog && (
        <ConfirmationDialog
          title={
            <>
              Are you sure you want to delete all selected radars (
              <span style={{ color: "red" }}>{selected.length}</span> radars)?
            </>
          }
          description={
            <>
              This action will delete all selected radars (
              <span style={{ color: "red" }}>{selected.length}</span> radars)
              from the system. The radars will be permanently deleted after a
              period of time. Please note that, the deleted radars cannot be
              used for any purpose. You can only delete radars created by you.
              If you want to delete radars created by another user, please
              contact someone (admin) who has the permission to do.
            </>
          }
          onConfirm={(inputText) => handleSelectedTrashClick(inputText)}
          onCancel={() => setOpenDeleteAllSelectedRadarDialog(false)}
        />
      )}
    </Page>
  );
}
