// material
import { useMediaQuery } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { ChildrenProp } from "@/utils/types";
import { Breakpoint } from "@mui/system/createTheme/createBreakpoints";

// ----------------------------------------------------------------------

type Props = {
  children: ChildrenProp;
  width:
    | "xsDown"
    | "smDown"
    | "mdDown"
    | "lgDown"
    | "xlDown"
    | "xsUp"
    | "smUp"
    | "mdUp"
    | "lgUp"
    | "xlUp";
};

export function MHidden({ width, children }: Props) {
  const breakpoint = width.substring(0, 2) as Breakpoint;

  const hiddenUp = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up(breakpoint),
  );
  const hiddenDown = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down(breakpoint),
  );

  if (width.includes("Down")) {
    return hiddenDown ? <></> : <>{children}</>;
  }

  if (width.includes("Up")) {
    return hiddenUp ? <></> : <>{children}</>;
  }

  return <></>;
}
