import { differenceInHours, differenceInDays, addDays } from "date-fns";
import { InputDate } from "@/utils/types";

export function getTimeTillRemoval(date?: InputDate) {
  // Check if date is a valid Date
  if (!date || isNaN(new Date(date).valueOf())) {
    return { days: null, hours: null, error: "Invalid date" };
  }

  const removalDate = addDays(new Date(date), 30); // set the removal date to createdAt + 30 days
  const now = new Date();

  if (removalDate <= now) {
    return { days: 0, hours: 0 };
  }

  const timeRemainingInDays = differenceInDays(removalDate, now);
  const timeRemainingInHours = differenceInHours(removalDate, now) % 24;

  return {
    days: timeRemainingInDays,
    hours: timeRemainingInHours,
  };
}
