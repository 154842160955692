import { useArray } from "@/hooks";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { handleCatch, makeReq } from "@/utils/makeReq";

// contexts
import { AuthContext, AuthContextType } from "@/Contexts/AuthContext";
import {
  UploadedVersionContext,
  UploadedVersionContextType,
} from "@/Contexts/UploadedVersionContext";
import {
  SoftwareContext,
  SoftwareContextType,
} from "@/Contexts/SoftwareContext";
import { RadarContext, RadarContextType } from "@/Contexts/RadarContext";
import { ChildrenProp } from "@/utils/types";
import { BackupDTO, BackupModel } from "@/models/backup";
import { APIResponse, CallbackProp, DownloadInput } from "@/Contexts/types";

export type BackupContextType = {
  loading: boolean;
  backups: BackupModel[];
  getBackupById: (id: string) => BackupModel | undefined;
  createBackup: (state: BackupDTO) => Promise<void>;
  deleteAllBackups: () => Promise<void>;
  deleteBackup: (id: string) => Promise<void>;
  downloadBackup: ({
    id,
    downloadFileName,
    onProgress,
    abortController,
  }: DownloadInput) => Promise<void>;
  restoreBackup: (id: string) => Promise<void>;
  recallSoftware: (
    id: string,
    confirmText: string,
    callback: CallbackProp,
  ) => Promise<void>;
  fetchBackups: () => Promise<void>;
};

// Backup Context stands for 'Deleted Software packages'. It is used to manage software programs that are deleted by the user.
export const BackupContext = createContext<BackupContextType | null>(null);

type Props = {
  children: ChildrenProp;
};

export const BackupProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext) as AuthContextType;
  const { fetchSoftwares: fetchUploadedVersionSoftwares } = useContext(
    UploadedVersionContext,
  ) as UploadedVersionContextType;
  const { fetchSoftwares: fetchContextSoftwares } = useContext(
    SoftwareContext,
  ) as SoftwareContextType;

  const { fetchRadars } = useContext(RadarContext) as RadarContextType;

  const {
    array: backups,
    setArray: setBackups,
    push: pushBackup,
    remove: removeBackup,
  } = useArray<BackupModel>([], "_id");

  const fetchBackups = useCallback(async () => {
    try {
      const resData = await makeReq<
        {},
        APIResponse<{ backups: BackupModel[] }>
      >(`/backups`);
      setBackups(resData.backups);
    } catch (err) {
      handleCatch(err as Error);
    } finally {
      setLoading(false);
    }
  }, [setBackups]);

  const getBackupById = (id: string) => {
    return backups.find((el) => el._id === id);
  };

  //Fetch it once user logged in
  useEffect(() => {
    if (!user) return;
    fetchBackups();
  }, [fetchBackups, user]);

  // * CRUD Operations

  // Create a backup from uploaded version
  // Please note that, 'backup' is equivalent to 'deleted' software. It was just named 'backup' initially.
  const createBackup = async (state: BackupDTO) => {
    try {
      const resData = await makeReq<
        BackupDTO,
        APIResponse<{ backup: BackupModel }>
      >(
        `/backups/create`,
        {
          body: state,
        },
        "POST",
      );
      pushBackup(resData.backup);

      fetchBackups(); //Update the list of backups. Could be done with updating state!
      fetchUploadedVersionSoftwares(); //Update uploaded softwares list.

      setTimeout(() => {
        toast.success("Backup has been created successfully!");
      }, 1500);
      navigate("/dashboard/softwares/backups");
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const deleteAllBackups = async () => {
    try {
      await makeReq<undefined, APIResponse>(`/backups`, {}, "DELETE");
      setBackups([]);
      setTimeout(() => {
        toast.success("All backups have been deleted successfully!");
      }, 100);
      navigate("/dashboard/softwares/backups");
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const deleteBackup = async (id: string) => {
    try {
      await makeReq<undefined, APIResponse>(`/backups/${id}`, {}, "DELETE");
      navigate("/dashboard/softwares/backups");
      setTimeout(() => {
        toast.success("Backup has been deleted successfully!");
      }, 100);
      removeBackup(id);
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const downloadBackup = async ({
    id,
    downloadFileName,
    onProgress,
    abortController,
  }: DownloadInput) => {
    try {
      await makeReq<undefined, APIResponse>(
        `/backups/download/${id}`,
        { fileName: downloadFileName, onProgress },
        "GET",
        true,
        abortController,
      );
      toast.success("Backup has been downloaded successfully!");
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const restoreBackup = async (id: string) => {
    try {
      await makeReq<undefined, APIResponse>(
        `/backups/restore/${id}`,
        {},
        "PATCH",
      );

      // Update softwares states, and fetch uploaded softwares list.
      fetchContextSoftwares();
      fetchUploadedVersionSoftwares();

      setTimeout(() => {
        toast.success("Backup has been restored successfully!");
      }, 100);
      navigate("/dashboard/softwares/backups");
      removeBackup(id);
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  // Create a 'backup' from a RELEASED software.
  const recallSoftware = async (
    id: string,
    confirmText: string,
    callback: CallbackProp,
  ) => {
    try {
      const resData = await makeReq<
        { confirmText: string },
        APIResponse<{ backup: BackupModel }>
      >(
        `/backups/recall/${id}`,
        {
          body: {
            confirmText,
          },
        },
        "PATCH",
      );
      pushBackup(resData.backup); // Push the new backup to the list of backups.
      callback?.();

      // Update softwares list state
      fetchContextSoftwares();

      // Update radar list state
      fetchRadars();

      setTimeout(() => {
        toast.success("Software has been recalled successfully!");
      }, 100);
      navigate("/dashboard/softwares/backups");
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  return (
    <BackupContext.Provider
      value={{
        loading,
        backups,
        getBackupById,
        createBackup,
        deleteAllBackups,
        deleteBackup,
        downloadBackup,
        restoreBackup,
        recallSoftware,
        fetchBackups,
      }}
    >
      {children}
    </BackupContext.Provider>
  );
};
