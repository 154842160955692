import { useContext, useEffect } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import { Box, Drawer } from "@mui/material";
// components
import Scrollbar from "@/components/Scrollbar";
import NavSection from "@/components/NavSection";
import { MHidden } from "@/components/@material-extend";
//
import sidebarConfig from "./SidebarConfig";
import { AuthContext, AuthContextType } from "@/Contexts/AuthContext";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

// const AccountStyle = styled("div")(({ theme }) => ({
//   display: "flex",
//   alignItems: "center",
//   padding: theme.spacing(2, 2.5),
//   borderRadius: theme.shape.borderRadiusSm,
//   backgroundColor: theme.palette.grey[200],
// }));

// ----------------------------------------------------------------------

type Props = {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
};

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
}: Props) {
  const { pathname } = useLocation();
  const { user } = useContext(AuthContext) as AuthContextType;

  const filteredSidebarConfig = user
    ? sidebarConfig.filter((item) => {
        return !item.roles || item.roles.includes(user.role);
      })
    : [];

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [isOpenSidebar, onCloseSidebar, pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        height: "100%",
        "& .simplebar-content": {
          height: "100%",
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          px: 2.5,
          py: 3,
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <Box component={RouterLink} to="/" sx={{ display: "inline-flex" }}>
          <img
            src="/static/illustrations/logo_2.jpg"
            alt="logo"
            style={{
              maxWidth: "7rem",
              position: "relative",
              left: "50%",
              right: "50%",
            }}
          />
        </Box>
      </Box>

      <NavSection navConfig={filteredSidebarConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      <MHidden width="lgUp">
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>

      <MHidden width="lgDown">
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
            },
          }}
        >
          {renderContent}
        </Drawer>
      </MHidden>
    </RootStyle>
  );
}
