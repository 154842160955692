// icons
import { Icon, IconifyIcon } from "@iconify/react"; // @mui
import { Box } from "@mui/material";
import { BoxProps } from "@mui/material/Box";
import React from "react";
import { SxProp } from "@/utils/types"; // ----------------------------------------------------------------------

// ----------------------------------------------------------------------

type Props = BoxProps & {
  icon: IconifyIcon | string;
  sx?: SxProp;
};

export default function Iconify({ icon, sx, ...other }: Props) {
  return <Box component={Icon} icon={icon} sx={{ ...sx }} {...other} />;
}
