import { IndexedObject } from "@/utils/types";

export type SortOrder = "desc" | "asc";

export type SortResult = 0 | 1 | -1;

export type GetComparatorProps = {
  order: SortOrder;
  orderBy: string;
};

export type ApplySortFilterProps<T> = {
  array?: T[];
  comparator: (a: T, b: T) => SortResult;
  query: string;
};

export const sortIt =
  <T>(sortBy: keyof T) =>
  (a: T, b: T) => {
    if (a[sortBy] > b[sortBy]) {
      return 1;
    } else if (a[sortBy] < b[sortBy]) {
      return -1;
    }
    return 0;
  };

function descendingComparator<T extends IndexedObject>(
  a: T,
  b: T,
  orderBy: string,
): SortResult {
  const keys = orderBy.split(".") as (keyof T)[];
  const aProperty = keys.length > 1 ? a?.[keys[0]]?.[keys[1]] : a?.[orderBy];
  const bProperty =
    keys.length > 1 ? b?.[keys[0]]?.[keys[1]] : b?.[orderBy as keyof T];

  if (!aProperty && !bProperty) {
    return 0;
  }
  if (!aProperty) {
    return 1;
  }
  if (!bProperty) {
    return -1;
  }
  if (bProperty < aProperty) {
    return -1;
  }
  if (bProperty > aProperty) {
    return 1;
  }
  return 0;
}

export function getComparator<T extends IndexedObject>({
  order,
  orderBy,
}: GetComparatorProps) {
  return order === "desc"
    ? (a: T, b: T) => descendingComparator<T>(a, b, orderBy)
    : (a: T, b: T) => -descendingComparator(a, b, orderBy) as SortResult;
}
