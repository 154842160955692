import { useArray } from "@/hooks";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { handleCatch, makeReq } from "@/utils/makeReq";
import { AuthContext, AuthContextType } from "@/Contexts/AuthContext";
import {
  SoftwareContext,
  SoftwareContextType,
} from "@/Contexts/SoftwareContext";
import { useNavigate } from "react-router-dom";
import { ChildrenProp } from "@/utils/types";
import {
  ReleaseVersionModel,
  SoftwareDetailsModel,
  SoftwareUploadedVersionModel,
} from "@/models/uploadedVersion";
import { SoftwareModel } from "@/models/software";
import { APIResponse, DownloadInput } from "@/Contexts/types";

export type UploadedVersionContextType = {
  loading: boolean;
  softwares: SoftwareUploadedVersionModel[];
  fetchSoftwares: () => Promise<void>;
  getSoftwareById: (id: string) => SoftwareUploadedVersionModel | undefined;
  loadingDe: boolean;
  softwareDetails: SoftwareDetailsModel[];
  fetchExtractedVersion: (id: string, rtype: string) => Promise<void>;
  releaseVersion: (state: ReleaseVersionModel) => Promise<void>;
  countSoftwares: number;
  downloadUploadConfigFile: () => Promise<void>;
  setSoftwares: React.Dispatch<
    React.SetStateAction<SoftwareUploadedVersionModel[]>
  >;
  downloadNotReleasedSoftware: (
    input: DownloadInput & { versionPath: string },
  ) => Promise<void>;
};

export const UploadedVersionContext =
  createContext<UploadedVersionContextType | null>(null);

type Props = {
  children: ChildrenProp;
};

export const UploadedVersionProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [loadingDe, setLoadingDe] = useState(true);
  const [countSoftwares, setCountSoftwares] = useState(0);
  const { user } = useContext(AuthContext) as AuthContextType;
  const { fetchSoftwaresPage } = useContext(
    SoftwareContext,
  ) as SoftwareContextType;

  // Custom hooks to manage software packages.
  const { array: softwares, setArray: setSoftwares } =
    useArray<SoftwareUploadedVersionModel>([], "_id");

  const { array: softwareDetails, setArray: setExtractedVersion } =
    useArray<SoftwareDetailsModel>([], "_id");

  //
  const fetchSoftwares = useCallback(async () => {
    try {
      const resData = await makeReq<
        {},
        APIResponse<{
          softwares: SoftwareUploadedVersionModel[];
        }>
      >(`/softwares/versions`);
      setSoftwares(resData.softwares); // release version table data
      setCountSoftwares(resData.softwares.length); // home page counter for release version
    } catch (err) {
      handleCatch(err as Error);
    } finally {
      setLoading(false);
    }
  }, [setSoftwares]);

  const getSoftwareById = (id: string) => {
    return softwares.find((el) => el._id === Number(id));
  };

  // extract version for upload software version details
  const fetchExtractedVersion = async (id: string, rtype: string) => {
    setLoadingDe(true);
    try {
      if (id !== undefined) {
        const resData = await makeReq<
          { version: string; type: string },
          APIResponse<{
            softwareDetails: SoftwareDetailsModel[];
          }>
        >(
          `/softwares/exractversion`,
          { body: { version: id, type: rtype } }, //type: 'birdradar' to find which type of radar to extract
          "POST",
        );
        setExtractedVersion(resData.softwareDetails);
      }
    } catch (err) {
      handleCatch(err as Error);
      //navigate back to create new release page if you can not extract data
      setTimeout(() => {
        navigate("/dashboard/softwares/uploaded");
      }, 10);
    } finally {
      setLoadingDe(false);
    }
  };

  // Fetch it once user logged in
  useEffect(() => {
    if (!user) return;
    fetchSoftwares();
  }, [user, fetchSoftwares]);

  // * CRUD Operations
  const releaseVersion = async (state: ReleaseVersionModel) => {
    try {
      const resData = await makeReq<
        ReleaseVersionModel,
        APIResponse<{
          software: SoftwareModel;
          status: string;
        }>
      >(
        `/softwares/uploaded`,
        {
          body: state,
        },
        "POST",
      );
      fetchSoftwaresPage();

      if (resData.status === "success") {
        navigate("/dashboard/softwares/installed");
        setTimeout(() => {
          toast.success("Software has been declared successfully!");
        }, 100);
      }
    } catch (err) {
      const error = err as Error;
      if (error.message.includes("Error creating zip")) {
        toast.error("Error creating zip");
      } else if (error.message.includes("Can't create software")) {
        toast.error("Can't create software, try again");
      } else {
        handleCatch(error);
      }
    }
  };

  // After downloading, replace user._id with the appropriate user ID in the sd-upload-config.yml file
  const downloadUploadConfigFile = async () => {
    try {
      const fileName = `sd-upload-config.yml`;
      const resData = await makeReq<undefined, APIResponse>(
        `/softwares/uploadconfig`,
        { fileName },
        "GET",
        true,
      );

      if (!resData || resData.error) {
        toast.error("Error downloading the config file");
        return;
      }

      toast.success("Config file has been downloaded successfully!");
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const downloadNotReleasedSoftware = async ({
    downloadFileName,
    onProgress,
    versionPath,
  }: DownloadInput & { versionPath: string }) => {
    try {
      await makeReq<{ softwarePath: string }, APIResponse>(
        `/softwares/downloadnr`,
        {
          body: {
            softwarePath: versionPath,
          },
          fileName: downloadFileName,
          onProgress,
        },
        "POST",
        true,
      );
      toast.success("Software has been downloaded successfully!");
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  return (
    <UploadedVersionContext.Provider
      value={{
        loading,
        softwares,
        fetchSoftwares,
        getSoftwareById,
        loadingDe,
        softwareDetails,
        fetchExtractedVersion,
        releaseVersion,
        countSoftwares,
        downloadUploadConfigFile,
        setSoftwares,
        downloadNotReleasedSoftware,
      }}
    >
      {children}
    </UploadedVersionContext.Provider>
  );
};
