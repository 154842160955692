import { Helmet } from "react-helmet-async";
import React, { forwardRef } from "react";
// material
import { Box } from "@mui/material";
import { ChildrenProp } from "@/utils/types";

// ----------------------------------------------------------------------

type Props = {
  children: ChildrenProp;
  title?: string;
};

const Page = forwardRef<HTMLDivElement, Props>(
  ({ children, title = "", ...other }, ref) => (
    <Box ref={ref} {...other}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
    </Box>
  ),
);

Page.displayName = "Page"; // Added to prevent ESLint from throwing an error, because we are using forwardRef

export default Page;
