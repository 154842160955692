import React, { ReactNode, useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  TextField,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

type Props = {
  title: string | ReactNode;
  description: string | ReactNode;
  onConfirm?: (inputText: string) => void;
  onCancel?: () => void;
};

const ConfirmationDialog = ({
  title,
  description,
  onConfirm,
  onCancel,
}: Props) => {
  const [open, setOpen] = useState(true);
  const [inputText, setInputText] = useState("");

  const handleClose = () => {
    setOpen(false);
    if (onCancel) {
      onCancel();
    }
  };

  const handleConfirm = () => {
    setOpen(false);
    if (onConfirm) {
      onConfirm(inputText);
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        disableAutoFocus
      >
        <DialogTitle id="alert-dialog-title">
          {title}
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
            sx={{
              position: "absolute",
              right: 15,
              top: 8,
              padding: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {description}
          </DialogContentText>
          <TextField
            margin="dense"
            id="name"
            label="Please type the reason for removing... (max 150 characters)"
            type="text"
            fullWidth
            // add max 50 characters
            inputProps={{ maxLength: 150 }}
            value={inputText}
            onChange={(event) => setInputText(event.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={3}>
            <Button
              onClick={onCancel ? onCancel : handleClose}
              color="info"
              variant="contained"
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirm}
              color="error"
              variant="contained"
              disabled={inputText.trim() === ""}
            >
              Confirm
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmationDialog;
