import { AuthContext, AuthContextType } from "@/Contexts/AuthContext";
import React, { useContext } from "react";

const Logout = () => {
  const { logoutUser } = useContext(AuthContext) as AuthContextType;
  logoutUser();
  return <div />;
};

export default Logout;
