import { Dispatch, SetStateAction, useState } from "react";
import { IndexedObject } from "@/utils/types";

export const useArray = <T extends IndexedObject>(
  defaultValue: T[] = [],
  idKey: string = "id",
): {
  array: T[];
  setArray: Dispatch<SetStateAction<T[]>>;
  push: (element: T) => void;
  filter: (callback: (value: T, index: number, array: T[]) => boolean) => void;
  update: (id: string, updatedEl: T) => void;
  remove: (id: string) => void;
  clear: () => void;
} => {
  const [array, setArray] = useState(defaultValue);

  const push = (element: T) => {
    setArray((a) => [...a, element]);
  };

  const filter = (
    callback: (value: T, index: number, array: T[]) => boolean,
  ) => {
    setArray((a) => a.filter(callback));
  };

  const update = (id: string, updatedEl: T) => {
    setArray((a) =>
      a.map((el) => (el[idKey] === id ? { ...el, ...updatedEl } : el)),
    );
  };

  // * Useful Method
  // const insert = (index, newElement) => {
  //   setArray((a) => [
  //     ...a.slice(0, index),
  //     newElement,
  //     ...a.slice(index + 1, a.length),
  //   ]);
  // };

  const remove = (id: string) => {
    setArray((a) => a.filter((el) => el[idKey] !== id));
  };

  const clear = () => {
    setArray([]);
  };

  return { array, setArray, push, filter, update, remove, clear };
};
