// Password criteria checker
export function isPasswordValid(password: string) {
  return (
    /[A-Z]/.test(password) && // At least one uppercase letter
    /[a-z]/.test(password) && // At least one lowercase letter
    /\d/.test(password) && // At least one digit
    /[!@#$.]/.test(password) // At least one special character
  );
}

// Password generator
export function generatePassword(length?: number) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$.";
  const charactersLength = characters.length;
  let password = "";
  let passwordLength;

  do {
    password = "";
    passwordLength = length || Math.floor(Math.random() * (20 - 15 + 1)) + 15;

    for (let i = 0; i < passwordLength; i++) {
      password += characters.charAt(
        Math.floor(Math.random() * charactersLength),
      );
    }
  } while (!isPasswordValid(password));

  return password;
}
