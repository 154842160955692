import { useArray } from "@/hooks";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { handleCatch, makeReq } from "@/utils/makeReq";
import { AuthContext, AuthContextType } from "./AuthContext";
import { RadarContext, RadarContextType } from "./RadarContext";
import { useNavigate } from "react-router-dom";
import { ChartDataProp, ChildrenProp } from "@/utils/types";
import { SoftwareDTO, SoftwareModel } from "@/models/software";
import { RadarModel } from "@/models/radar";
import { APIResponse, CallbackProp, DownloadInput } from "@/Contexts/types";

type SizeMetrics = {
  chartData: ChartDataProp;
};

export type SoftwareContextType = {
  loading: boolean;
  softwares: SoftwareModel[];
  getSoftwareById: (id: string) => SoftwareModel | undefined;
  createSoftware: (
    state: SoftwareDTO,
    callback?: CallbackProp,
  ) => Promise<void>;
  deleteSoftware: (id: string) => Promise<void>;
  fetchSoftwaresPage: () => Promise<void>;
  downloadSoftware: ({
    id,
    downloadFileName,
    onProgress,
    abortController,
  }: DownloadInput) => Promise<void>;
  checkUsedSoftwares: (id: string) => Promise<void | { radars: RadarModel[] }>;
  sizeMetrics: SizeMetrics;
  fetchSoftwares: () => Promise<void>;
};

export const SoftwareContext = createContext<SoftwareContextType | null>(null);

type Props = {
  children: ChildrenProp;
};

export const SoftwaresProvider = ({ children }: Props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext) as AuthContextType;
  const { fetchRadars } = useContext(RadarContext) as RadarContextType;

  // This custom hook is used to manage RELEASED softwares.
  const {
    array: softwares,
    setArray: setSoftwares,
    push: pushSoftware,
    remove: removeSoftware,
  } = useArray<SoftwareModel>([], "_id");

  const [sizeMetrics, setSizeMetrics] = useState<SizeMetrics>({
    chartData: [],
  });

  const fetchSoftwares = useCallback(async () => {
    try {
      const resData = await makeReq<
        {},
        APIResponse<{ softwares: SoftwareModel[] }>
      >(`/softwares`);
      setSoftwares(resData.softwares);
    } catch (err) {
      handleCatch(err as Error);
    } finally {
      setLoading(false);
    }
  }, [setSoftwares]);

  const fetchSizeMetrics = useCallback(async () => {
    try {
      const resData = await makeReq<
        undefined,
        APIResponse<{ chartData: ChartDataProp }>
      >(`/softwares/sizemetrics`, {}, "GET");
      setSizeMetrics({ chartData: resData.chartData });
      return resData;
    } catch (err) {
      handleCatch(err as Error);
      return Promise.resolve();
    }
  }, []);

  const getSoftwareById = (id: string) => {
    return softwares.find((el) => el._id === id);
  };

  const fetchSoftwaresPage = async () => {
    try {
      const resData = await makeReq<
        {},
        APIResponse<{ softwares: SoftwareModel[] }>
      >(`/softwares`);
      setSoftwares(resData.softwares);
    } catch (err) {
      handleCatch(err as Error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch it once user logged in
  useEffect(() => {
    if (!user) return;
    fetchSoftwares();
    fetchSizeMetrics();
  }, [user, fetchSizeMetrics, fetchSoftwares]);

  // * CRUD Operations
  const createSoftware = async (
    state: SoftwareDTO,
    callback?: CallbackProp,
  ) => {
    try {
      const resData = await makeReq<
        SoftwareDTO,
        APIResponse<{ software: SoftwareModel }>
      >(`/softwares`, { body: state }, "POST");
      toast.success("Software has been created successfully!");
      pushSoftware(resData.software);
      callback?.();
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const deleteSoftware = async (id: string) => {
    try {
      await makeReq<undefined, APIResponse>(`/softwares/${id}`, {}, "DELETE");
      fetchRadars();
      navigate("/dashboard/softwares/installed");
      removeSoftware(id);
      setTimeout(() => {
        toast.success("Software has been deleted successfully!");
      }, 100);
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const checkUsedSoftwares = async (id: string) => {
    try {
      return await makeReq<undefined, APIResponse<{ radars: RadarModel[] }>>(
        `/softwares/checkused/${id}`,
        {},
        "GET",
      );
    } catch (err) {
      handleCatch(err as Error);
      return Promise.resolve();
    }
  };

  // Download software FILE.
  const downloadSoftware = async ({
    id,
    downloadFileName,
    onProgress,
    abortController,
  }: DownloadInput) => {
    try {
      await makeReq<undefined, APIResponse>(
        `/softwares/download/${id}`,
        { fileName: downloadFileName, onProgress },
        "GET",
        true,
        abortController,
      );
      toast.success("Software has been downloaded successfully!");
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  return (
    <SoftwareContext.Provider
      value={{
        loading,
        softwares,
        getSoftwareById,
        createSoftware,
        deleteSoftware,
        fetchSoftwaresPage,
        downloadSoftware,
        checkUsedSoftwares,
        sizeMetrics,
        fetchSoftwares,
      }}
    >
      {children}
    </SoftwareContext.Provider>
  );
};
