import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import NotFound from "@/pages/Page404"; // material
import {
  Button,
  Card,
  Checkbox,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  List,
  ListItem,
  ListItemText,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import PublishIcon from "@mui/icons-material/Publish";
import LinearProgress from "@mui/material/LinearProgress"; // components
import Page from "@/components/Page";
import AlertDialog from "@/components/dialogs/AlertDialog";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog";
import Iconify from "@/components/Iconify";
import DownloadProgress from "@/components/common/DownloadProgress";
import { toast } from "react-toastify"; // Context
import {
  UploadedVersionContext,
  UploadedVersionContextType,
} from "@/Contexts/UploadedVersionContext"; // breadcrumb
import { Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { BackupContext, BackupContextType } from "@/Contexts/BackupContext";
import { DownloadStatusType } from "@/utils/types";
import { SoftwareType } from "@/models/software"; //

//

export default function UploadedVersionsDetails() {
  type ProcessingPageType =
    | "release_processing"
    | "remove_release"
    | "zipping_for_download";
  // Contexts
  const {
    softwares,
    loading,
    softwareDetails,
    fetchExtractedVersion,
    releaseVersion,
    loadingDe,
    downloadNotReleasedSoftware,
  } = useContext(UploadedVersionContext) as UploadedVersionContextType;

  const { createBackup } = useContext(BackupContext) as BackupContextType;

  // get ID of the page, ID of software
  const { id } = useParams();

  const software = softwares[Number(id)];

  // States
  const [versionPlusExtra, setVersionPlusExtra] = useState<string>();
  const [processingPage, setProcessingPage] =
    useState<ProcessingPageType | null>(null);
  const [downloadProgress, setDownloadProgress] = useState(0);
  const [downloadStatus, setDownloadStatus] =
    useState<DownloadStatusType | null>(null);

  const handleProgress = (progress: number) => {
    setDownloadProgress(progress);
    if (progress === 100) {
      setDownloadStatus("completed");
    } else {
      setDownloadStatus("downloading");
    }
  };

  type CheckboxesType = {
    checkbox1: boolean;
    checkbox2: boolean;
    checkbox3: boolean;
  };

  const [checkboxes, setCheckboxes] = useState<CheckboxesType>({
    checkbox1: false,
    checkbox2: false,
    checkbox3: false,
  });

  // !!! Static data, so update this if you add more software types !!!
  const getCheckboxValues = () => {
    const softwareType = software?.rtype;
    if (softwareType === "elvira") return ["ELVIRA"];
    if (softwareType === "iris") return ["IRIS"];

    const checkboxNames = ["MAX", "3DFIXED", "3DFLEX"];
    return checkboxNames.filter(
      (_, i) => checkboxes[`checkbox${i + 1}` as keyof CheckboxesType],
    );
  };

  // State for dialog
  const [openReleaseVersionDialog, setOpenReleaseVersionDialog] =
    useState(false);
  const [openBackupVersionDialog, setOpenBackupReleaseDialog] = useState(false);

  // Call GET EP one time (also if loading changes)
  useEffect(() => {
    fetchExtractedVersion(software?.version, software?.rtype);
  }, [fetchExtractedVersion, loading, software?.rtype, software?.version]);

  // Set merged version if input value updated or page loaded
  useEffect(() => {
    setVersionPlusExtra(softwareDetails[0]?.version);
  }, [loading, softwareDetails]);

  // check if all versions are the same
  const allVersionsMatch = softwareDetails.every(
    (item) => item.version === softwareDetails[0]?.version,
  );

  // all ubuntu versions in one array
  const allUbuntuVersions = softwareDetails.flatMap(
    (item) => item.ubuntu_version,
  );

  const handleZipAndDownload = async () => {
    setProcessingPage("zipping_for_download");

    const versionForDownload = software?.version;

    if (!versionForDownload) {
      toast.error("No software to download.");
      return;
    }

    const downloadFileName = versionForDownload.includes(".zip")
      ? versionForDownload
      : `${versionForDownload}.zip`;

    setDownloadProgress(0);
    setDownloadStatus(null);

    let versionPath = software?.versionPath + "/" + software?.version;

    try {
      // Download software
      await downloadNotReleasedSoftware({
        versionPath,
        downloadFileName,
        onProgress: handleProgress,
      });

      // After download is complete
      setTimeout(() => {
        setProcessingPage(null);
      }, 1500);
    } catch (error) {
      toast.error((error as Error).message);
    }
  };

  // declare version
  const handleReleaseVersion = async (event: SyntheticEvent) => {
    event.preventDefault();

    const checkboxValues = getCheckboxValues();

    setProcessingPage("release_processing");
    await releaseVersion({
      version: software?.version,
      versionPlusExtra: versionPlusExtra!,
      radarType: software?.rtype,
      validTypes: checkboxValues,
      ubuntuVersion: allUbuntuVersions,
    });

    setOpenReleaseVersionDialog(false);
  };

  // remove version
  const handleBackupVersion = async (inputText: string) => {
    setProcessingPage("remove_release");

    // call remove version function
    await createBackup({
      versionPath: software?.versionPath,
      versionName: software?.version,
      versionUbuntuVersions: allUbuntuVersions,
      softwareType: software?.rtype.toUpperCase() as SoftwareType,
      removeReason: inputText,
    });

    setOpenBackupReleaseDialog(false);
  };

  return (
    <Page
      title={`${
        loading
          ? "Loading"
          : software
          ? software.version + " | Robin Radar Systems Admin"
          : "404 | Not Found"
      }`}
    >
      {loading ? (
        <Skeleton variant="rectangular" height="600px" />
      ) : software ? (
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              component={RouterLink}
              to="/"
              underline="hover"
              color="inherit"
            >
              Home
            </Link>
            <Link
              component={RouterLink}
              to="/dashboard/softwares/uploaded"
              underline="hover"
              color="inherit"
            >
              Uploaded softwares (Create Release)
            </Link>
            <Typography color="text.primary">{software?.version}</Typography>
          </Breadcrumbs>
          <Container>
            <Typography variant="h4" gutterBottom>
              <b>
                Uploaded software (Create Release) <i>({software?.version})</i>
              </b>
            </Typography>
            {!processingPage && (
              <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2}
              >
                <Grid item>
                  <Typography variant="h5" gutterBottom>
                    Software details
                  </Typography>
                  <Card
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      maxWidth: "40rem",
                    }}
                  >
                    <Stack
                      direction="column"
                      alignItems="flex-start"
                      justifyContent="center"
                    >
                      <Typography variant="body1" gutterBottom>
                        <b>Software version:</b> {software?.version}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <b>Software type: </b>
                        {software.versionPath.split("/").pop()
                          ? software.versionPath.split("/").pop()?.toUpperCase()
                          : "N/A"}
                      </Typography>
                      <Typography variant="body1" gutterBottom>
                        <b>Software version path:</b>
                        {software?.versionPath + "/" + software?.version}
                      </Typography>

                      <Typography variant="body1" gutterBottom>
                        <b>Ubuntu version(s):</b>
                      </Typography>

                      {loadingDe ? (
                        Array.from({ length: 5 }).map((_, index) => (
                          <Skeleton variant="text" width="100%" key={index} />
                        ))
                      ) : (
                        <List>
                          {softwareDetails.map((item, index) => (
                            <ListItem key={index}>
                              <ListItemText
                                primary={<b>{item.ubuntu_version}</b>}
                                secondary={
                                  <Box component="div">
                                    <Typography
                                      variant="body2"
                                      component="span"
                                      style={{ wordWrap: "break-word" }}
                                    >
                                      <b>Extracted version:</b> {item.version}
                                    </Typography>
                                    <br />
                                    <Typography
                                      variant="body2"
                                      component="span"
                                      style={{ wordWrap: "break-word" }}
                                    >
                                      <b>Package file:</b> {item.path}
                                    </Typography>
                                  </Box>
                                }
                                disableTypography
                              />
                            </ListItem>
                          ))}
                        </List>
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      alignItems="flex-start"
                      justifyContent="space-between"
                      mt={2}
                      gap="2rem"
                    >
                      <Button
                        sx={{
                          textTransform: "none",
                        }}
                        variant="contained"
                        color="error"
                        onClick={() => setOpenBackupReleaseDialog(true)}
                        startIcon={<Iconify icon="eva:trash-2-outline" />}
                      >
                        Remove this version
                      </Button>
                      <Button
                        sx={{
                          textTransform: "none",
                        }}
                        variant="contained"
                        color="primary"
                        onClick={handleZipAndDownload}
                        startIcon={<Iconify icon={"ic:baseline-download"} />}
                      >
                        Download this version
                      </Button>
                    </Stack>
                  </Card>
                </Grid>
                <Grid item>
                  <Typography variant="h5" gutterBottom>
                    Installation options
                  </Typography>
                  <Card
                    sx={{
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      height: "100%",
                      maxWidth: "40rem",
                    }}
                  >
                    <Stack
                      direction="column"
                      alignItems="flex-start"
                      justifyContent="center"
                      gap={1}
                    >
                      <Typography variant="body1" gutterBottom>
                        <b>Radar type(s) for installation</b>
                      </Typography>
                      {software?.versionPath.split("/").pop() ===
                        "birdradar" && (
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(event) =>
                                  setCheckboxes({
                                    ...checkboxes,
                                    checkbox1: event.target.checked,
                                  })
                                }
                              />
                            }
                            label="MAX"
                          ></FormControlLabel>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(event) =>
                                  setCheckboxes({
                                    ...checkboxes,
                                    checkbox2: event.target.checked,
                                  })
                                }
                              />
                            }
                            label="3DFIXED"
                          ></FormControlLabel>
                          <FormControlLabel
                            control={
                              <Checkbox
                                onChange={(event) =>
                                  setCheckboxes({
                                    ...checkboxes,
                                    checkbox3: event.target.checked,
                                  })
                                }
                              />
                            }
                            label="3DFLEX"
                          ></FormControlLabel>
                        </FormGroup>
                      )}
                      {software?.versionPath.split("/").pop() === "elvira" && (
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox defaultChecked disabled />}
                            label="ELVIRA"
                          ></FormControlLabel>
                        </FormGroup>
                      )}
                      {software?.versionPath.split("/").pop() === "iris" && (
                        <FormGroup row>
                          <FormControlLabel
                            control={<Checkbox defaultChecked disabled />}
                            label="IRIS"
                          ></FormControlLabel>
                        </FormGroup>
                      )}
                      {loadingDe ? (
                        <Skeleton variant="text" width="100%" />
                      ) : (
                        <Typography variant="body1" gutterBottom>
                          {allVersionsMatch ? (
                            <>
                              You are going to release this version:&nbsp;
                              <b>{softwareDetails[0]?.version}</b>
                            </>
                          ) : (
                            <span style={{ color: "red" }}>
                              Version mismatch detected. Please compare versions
                              in the Packages file - or see the extracted
                              versions above.
                            </span>
                          )}
                        </Typography>
                      )}

                      {loadingDe ? (
                        <Skeleton variant="text" width="100%" />
                      ) : (
                        allVersionsMatch && (
                          <Typography variant="body1" gutterBottom>
                            This release will include the following Ubuntu
                            versions:&nbsp;
                            {softwareDetails.map((item, index) => (
                              <span key={index}>
                                <b>
                                  {item.ubuntu_version}
                                  {index < softwareDetails.length - 1 && ", "}
                                </b>
                              </span>
                            ))}
                          </Typography>
                        )
                      )}

                      <Button
                        variant="contained"
                        sx={{ textTransform: "none" }}
                        onClick={() => setOpenReleaseVersionDialog(true)}
                        startIcon={<PublishIcon />}
                        disabled={
                          softwareDetails[0]?.version === "" ||
                          !allVersionsMatch
                        }
                      >
                        Release this version
                      </Button>
                    </Stack>
                  </Card>
                </Grid>
              </Grid>
            )}
            {processingPage && (
              <Box>
                <Typography align="center" variant="body1">
                  {processingPage === "release_processing" &&
                    "Generating zip file, installing the release..."}
                  {processingPage === "remove_release" &&
                    "Removing release and moving the release to backup..."}
                  {processingPage === "zipping_for_download" &&
                    downloadStatus !== "completed" &&
                    downloadStatus !== "downloading" && (
                      <Box>
                        <Typography align="center" variant="body1">
                          Zipping the software for download...
                        </Typography>
                        <Typography align="center" variant="body1">
                          This may take a while. Please wait, and do not close
                          this page.
                        </Typography>
                      </Box>
                    )}

                  {processingPage === "zipping_for_download" &&
                    downloadStatus === "completed" &&
                    "Download finished! Returning to the previous page..."}
                </Typography>
                {(processingPage !== "zipping_for_download" ||
                  (processingPage === "zipping_for_download" &&
                    downloadStatus !== "completed" &&
                    downloadStatus !== "downloading")) && <LinearProgress />}
              </Box>
            )}

            <DownloadProgress
              downloadStatus={downloadStatus}
              downloadProgress={downloadProgress}
            />
          </Container>
        </>
      ) : (
        <NotFound />
      )}
      {openReleaseVersionDialog && (
        <AlertDialog
          onConfirm={handleReleaseVersion}
          onCancel={() => setOpenReleaseVersionDialog(false)}
          title={`Are you sure you want to release version ${versionPlusExtra}?`}
          description={"This action will create a release with this version."}
        />
      )}
      {openBackupVersionDialog && (
        <ConfirmationDialog
          onConfirm={(inputText) => handleBackupVersion(inputText)}
          onCancel={() => setOpenBackupReleaseDialog(false)}
          title={`Are you sure you want to remove release ${versionPlusExtra}?`}
          description={
            "This action will remove this release file from the server. This action cannot be undone, unless you upload the file again to the server, or you restore the backup. " +
            "All backups will be permanently deleted in 30 days."
          }
        />
      )}
    </Page>
  );
}
