import * as React from "react";
import { ChangeEvent, MouseEvent, useContext, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import _ from "lodash";

// material
import {
  Button,
  Card,
  Checkbox,
  Container,
  FormControlLabel,
  SelectChangeEvent,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

// components
import Page from "@/components/Page";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Iconify from "@/components/Iconify";
import CountryAutocomplete from "@/components/formComponents/CountryAutocomplete";
import { format } from "date-fns";
import Link from "@mui/material/Link";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { SoftwareModel } from "@/models/software";
import { UserModel } from "@/models/user";
import { toast } from "react-toastify";

// Contexts
import { RadarContext, RadarContextType } from "@/Contexts/RadarContext";
import { UserContext, UserContextType } from "@/Contexts/UserContext";
import {
  SoftwareContext,
  SoftwareContextType,
} from "@/Contexts/SoftwareContext";

//countries data
import countries, { CountryType } from "@/data/countries";
import { sortIt } from "@/utils/sort";

export default function NewRadar() {
  // Contexts
  const { createRadar } = useContext(RadarContext) as RadarContextType;
  const { users } = useContext(UserContext) as UserContextType;
  const { softwares } = useContext(SoftwareContext) as SoftwareContextType;

  // call sort then filter software packages by recall by passing 'softwareType' as sortBy
  let sortedSoftwares = [...softwares];
  sortedSoftwares.sort(sortIt<SoftwareModel>("softwareType"));
  let sortedNotRecalledSoftwares = sortedSoftwares.filter((obj) => {
    return !obj.recalled;
  });

  // User inputs
  const [customer, setCustomer] = useState<UserModel>();
  const [stype, setStype] = useState("");
  const [software, setSoftware] = useState<SoftwareModel | null>(null);
  const [resetCustomerField, setResetCustomerField] = useState("");
  const [resetLocationField, setResetLocationField] = useState("2"); //it has to be different from resetTextfield as it is the default key for Autocomplete
  const [resetVersionField, setResetVersionField] = useState("3");
  const [selectedCountry, setSelectedCountry] = useState<CountryType | null>(
    null,
  );
  const [notes, setNotes] = useState("");
  const [detailedLocation, setDetailedLocation] = useState("");
  const [radarAlias, setRadarAlias] = useState("");
  const [isTestRadar, setIsTestRadar] = useState(false);

  //
  const handleTestCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
    setIsTestRadar(event.target.checked);
  };

  const changeStype = (event: SelectChangeEvent) => {
    setStype(event.target.value);
    setSoftware(null); // reset the 'select software version' value to None
  };

  // transfer values from other fields on REQUEST button click
  const transferValue = async (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    if (!customer) {
      toast.error("Please choose the customer.");
      return;
    }
    const val = {
      alias: radarAlias,
      customer,
      stype,
      software,
      countryCode: selectedCountry ? selectedCountry.code : undefined,
      detailedLocation,
      notes,
      testing: isTestRadar,
    };
    await createRadar(val);
    clearState();
  };

  // Clear states after a request has been sent
  const clearState = () => {
    setResetCustomerField(_.uniqueId()); //it changes the key of customer AutoComplete (same for location and version)
    setResetLocationField(_.uniqueId() + "2");
    setResetVersionField(_.uniqueId() + "3");
    setStype("");
    setSoftware(null);
    setSelectedCountry(null);
    setNotes("");
    setDetailedLocation("");
    setRadarAlias("");
    setIsTestRadar(false);
  };

  return (
    <Page title="Create Radar | Robin Radar Systems Admin">
      <Breadcrumbs aria-label="breadcrumb">
        <Link component={RouterLink} to="/" underline="hover" color="inherit">
          Home
        </Link>
        <Typography color="text.primary">Create new radar</Typography>
      </Breadcrumbs>
      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Create new radar for customer
          </Typography>
        </Stack>
        <Card
          sx={{
            p: 2,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            minWidth: 300,
            maxWidth: "32.5rem",
          }}
        >
          <Container>
            <Stack
              direction="column"
              alignItems="left"
              justifyContent="left"
              mt={2}
              gap="10px"
            >
              <TextField
                id="outlined-basic"
                label="Radar alias (i.e. internal-dev1, etc.)"
                sx={{ minWidth: 300, maxWidth: 450 }}
                autoComplete="off"
                value={radarAlias}
                inputProps={{ maxLength: 50 }}
                onChange={(event) => setRadarAlias(event.target.value)}
              />
              <Autocomplete
                key={resetCustomerField}
                id="combo-box-demo"
                sx={{ minWidth: 300, maxWidth: 450 }}
                options={users}
                getOptionLabel={(user) => user.name + " - (" + user.email + ")"}
                isOptionEqualToValue={(option, value) =>
                  option.name === value.name
                }
                onChange={(event, newValue) => {
                  setCustomer(newValue!);
                }}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option._id}>
                      {option.name + " - (" + option.email + ")"}
                    </li>
                  );
                }}
                renderInput={(params) => (
                  <TextField
                    required
                    {...params}
                    label="Customer"
                    error={!customer} // if customer is not selected, show error
                    helperText={!customer && "Please select a customer"}
                  />
                )}
              />

              <FormControl>
                <InputLabel id="softwareTypeid">Software type</InputLabel>
                <Select
                  sx={{ minWidth: 300, maxWidth: 450 }}
                  labelId="selectsoftwareType-label"
                  id="selectsoftwareType"
                  value={stype}
                  label="Software Type"
                  onChange={changeStype}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {Array.from(
                    new Set(
                      sortedNotRecalledSoftwares.map(
                        (software) => software.softwareType,
                      ),
                    ),
                  ).map((type) => (
                    <MenuItem key={type} value={type}>
                      {type}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl>
                <Autocomplete
                  key={resetVersionField}
                  disabled={!stype}
                  id="selectversion"
                  options={sortedNotRecalledSoftwares.filter(
                    (sw) => sw.softwareType === stype,
                  )}
                  getOptionLabel={(option) =>
                    option.version +
                    " - " +
                    format(new Date(option.createdAt), "yyyy-MM-dd, HH:mm:ss")
                  }
                  value={software}
                  onChange={(event, newValue) => {
                    setSoftware(newValue!);
                  }}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option._id}>
                        <>
                          <b>{option.version}</b>
                          {" - " +
                            format(
                              new Date(option.createdAt),
                              "yyyy-MM-dd, HH:mm:ss",
                            )}
                        </>
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Software version" />
                  )}
                />
              </FormControl>

              <CountryAutocomplete
                resetKey={resetLocationField}
                countries={countries}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
              />
              <TextField
                id="outlined-basic"
                label="Radar location details (i.e. city, area, etc.)"
                sx={{ minWidth: 300, maxWidth: 450 }}
                autoComplete="off"
                value={detailedLocation}
                inputProps={{ maxLength: 100 }}
                onChange={(event) => setDetailedLocation(event.target.value)}
              />
              <TextField
                id="outlined-multiline-static"
                label="Notes, etc."
                sx={{ minWidth: 300, maxWidth: 450 }}
                autoComplete="off"
                multiline
                rows={3}
                value={notes}
                inputProps={{ maxLength: 250 }}
                onChange={(event) => setNotes(event.target.value)}
              />
              <Tooltip title="Toggle this checkbox if this radar is meant for testing purposes. It will not be visible in the main radar list, unless you select the correct option in the filter.">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isTestRadar}
                      onChange={handleTestCheckboxChange}
                      name="checkedB"
                      color="primary"
                    />
                  }
                  label="Test radar"
                />
              </Tooltip>
            </Stack>
            <Stack
              direction="row"
              alignItems="left"
              justifyContent="left"
              mb={2}
              mt={5}
              gap="10px"
            >
              <Button
                variant="contained"
                onClick={transferValue}
                startIcon={<Iconify icon="eva:plus-fill" />}
                sx={{ textTransform: "none" }}
                disabled={!customer}
              >
                New radar
              </Button>
            </Stack>
          </Container>
        </Card>
      </Container>
    </Page>
  );
}
