import React, { useMemo } from "react";

// material
import { CssBaseline, ThemeOptions } from "@mui/material";
import {
  createTheme,
  StyledEngineProvider,
  ThemeProvider,
} from "@mui/material/styles";

// theme options
import { ChildrenProp } from "@/utils/types";
import shape from "@/theme/shape";
import shadows, { customShadows } from "@/theme/shadows";
import palette from "@/theme/palette";
import typography from "@/theme/typography";
import ComponentsOverrides from "@/theme/overrides";

// ----------------------------------------------------------------------

type Props = {
  children: ChildrenProp;
};

export default function ThemeConfig({ children }: Props) {
  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette,
      shape,
      typography,
      shadows,
      customShadows,
    }),
    [],
  );

  const theme = createTheme(themeOptions);
  theme.components = ComponentsOverrides(theme);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
