import React, { SyntheticEvent, useContext, useEffect, useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import Link from "@mui/material/Link"; // material
import {
  Button,
  Card,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField"; // icons
import { useParams } from "react-router";
import NotFound from "@/pages/Page404";
// ----------------------------------------------------------------------
import CancelIcon from "@mui/icons-material/Cancel"; // components
import Iconify from "@/components/Iconify";
import Page from "@/components/Page";
import { RadarContext, RadarContextType } from "@/Contexts/RadarContext";
import { UserContext, UserContextType } from "@/Contexts/UserContext";
import { toast } from "react-toastify";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import AlertDialog from "@/components/dialogs/AlertDialog";
import ConfirmationDialog from "@/components/dialogs/ConfirmationDialog";
import CountryAutocomplete from "@/components/formComponents/CountryAutocomplete"; // Countries data for autocomplete
import countries, { CountryType } from "@/data/countries";
import { RadarModel } from "@/models/radar";
import { UserModel } from "@/models/user";

// ----------------------------------------------------------------------

export default function RadarDetails() {
  // States for dialogs
  const [openRemoveRadarDialog, setOpenRemoveRadarDialog] = useState(false);
  const [openDiscardChangesDialog, setOpenDiscardChangesDialog] =
    useState(false);
  const [openSaveChangesDialog, setOpenSaveChangesDialog] = useState(false);

  // Contexts
  const { radars, getRadarById, loading, editRadar, deleteRadar } = useContext(
    RadarContext,
  ) as RadarContextType;
  const { users } = useContext(UserContext) as UserContextType;
  const { id } = useParams();

  const [radar, setRadar] = useState<RadarModel>();
  const [radarNotFound, setRadarNotFound] = useState(false);
  const [resetCustomerfield] = useState("");
  const [resetlocationfield] = useState("2"); //it has to be different from resetTextField as it is the default key for Autocomplete.
  const [selectedCountry, setSelectedCountry] = useState<CountryType | null>(
    null,
  );

  // Radar fields
  const [detailedLocation, setDetailedLocation] = useState("");
  const [alias, setAlias] = useState("");
  const [customer, setCustomer] = useState<UserModel | null>(null);
  const [notes, setNotes] = useState("");
  const [testing, setTesting] = useState(false);

  //table
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) return;
    if (loading) return;

    const el = getRadarById(id);

    if (!el) {
      setRadarNotFound(true);
      toast.error("The radar with this id doesn't exist.");
    } else {
      setRadar({ ...el });
      setAlias(el.alias ?? "");
      setCustomer(el.customer);
      setNotes(el.notes ?? "");
      setDetailedLocation(el.detailedLocation || "");
      setTesting(el.testing || false);

      if (el && el.countryCode) {
        const selectedCountry = countries.find(
          (country) => country.code === el.countryCode,
        );
        setSelectedCountry(selectedCountry ?? null);
      }
    }
  }, [getRadarById, radars, loading, id]);

  //handle functions for dialogs
  const handleRemoveRadar = async (inputText: string) => {
    if (id) {
      await deleteRadar(id, inputText);
      setOpenRemoveRadarDialog(false);
    }
  };

  const handleDiscardChanges = (e: SyntheticEvent) => {
    e.preventDefault();
    setOpenDiscardChangesDialog(false);
    navigate(-1);
  };

  const handleSaveChanges = async (e: SyntheticEvent) => {
    e.preventDefault();
    setOpenSaveChangesDialog(false);

    const updatedRadar = {
      detailedLocation: detailedLocation,
      alias: alias,
      customer,
      notes: notes,
      countryCode: selectedCountry ? selectedCountry.code : null,
      testing: testing,
    };
    navigate(-1);
    if (id) {
      await editRadar(id, updatedRadar);
    }
  };

  return (
    <>
      {radarNotFound || loading ? (
        <Page title="404 | Not Found">
          {loading ? (
            <Skeleton variant="rectangular" height="600px" />
          ) : (
            <NotFound />
          )}
        </Page>
      ) : (
        <Page title={radar?.customer?.name + " | Radar Details"}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link
              component={RouterLink}
              to="/"
              underline="hover"
              color="inherit"
            >
              Home
            </Link>
            <Link
              component={RouterLink}
              to="/dashboard/radars/list"
              underline="hover"
              color="inherit"
            >
              Radars
            </Link>
            <Typography>Radar details: {radar?.customer?.name}</Typography>
            <Typography color="text.primary">Radar edit</Typography>
          </Breadcrumbs>
          <Container>
            <Grid justifyContent="flex-start" alignItems="flex-start">
              <Grid item style={{ marginBottom: "1rem" }}>
                <Typography variant="h4" gutterBottom>
                  Radar
                </Typography>
                <Card
                  sx={{
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    minWidth: "60vw",
                    maxWidth: "32.5rem",
                  }}
                >
                  <Stack
                    direction="column"
                    alignItems="flex-start"
                    justifyContent="space-around"
                    spacing={1}
                  >
                    <Typography variant="body1" gutterBottom>
                      <b>Radar ID:</b> {radar?._id}
                    </Typography>
                    <TextField
                      id="outlined-basic"
                      label="Radar alias"
                      sx={{ minWidth: 550, maxWidth: 650 }}
                      autoComplete="off"
                      value={alias}
                      inputProps={{ maxLength: 50 }}
                      onChange={(event) => setAlias(event.target.value)}
                    />
                    <Autocomplete
                      key={resetCustomerfield}
                      id="combo-box-demo"
                      sx={{ minWidth: 550, maxWidth: 650 }}
                      options={users}
                      getOptionLabel={(user) =>
                        user.name + " - (" + user.email + ")"
                      }
                      isOptionEqualToValue={(option, value) =>
                        option.name === value.name
                      }
                      value={customer}
                      onChange={(event, newValue) => {
                        setCustomer(newValue);
                        console.log(newValue);
                      }}
                      renderOption={(props, option) => {
                        return (
                          <li {...props} key={option._id}>
                            {option.name + " - (" + option.email + ")"}
                          </li>
                        );
                      }}
                      renderInput={(params) => (
                        <TextField required {...params} label="Customer" />
                      )}
                    />
                    <CountryAutocomplete
                      id="country-select-demo"
                      minWidth={300}
                      maxWidth={450}
                      countries={countries}
                      resetKey={resetlocationfield}
                      selectedCountry={selectedCountry}
                      setSelectedCountry={setSelectedCountry}
                    />
                    <TextField
                      id="outlined-basic"
                      label="Radar location details"
                      sx={{ minWidth: 550, maxWidth: 650 }}
                      autoComplete="off"
                      value={detailedLocation}
                      inputProps={{ maxLength: 100 }}
                      onChange={(event) =>
                        setDetailedLocation(event.target.value)
                      }
                    />
                    <TextField
                      helperText="*required"
                      id="outlined-multiline-static"
                      label="Comments, notes, etc."
                      sx={{ minWidth: 550, maxWidth: 650 }}
                      autoComplete="off"
                      multiline
                      rows={3}
                      value={notes}
                      inputProps={{ maxLength: 250 }}
                      onChange={(event) => setNotes(event.target.value)}
                    />

                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={Boolean(testing)}
                          onChange={(e) => setTesting(e.target.checked)}
                          name="isTesting"
                        />
                      }
                      label="Testing radar"
                    />

                    <Typography variant="body1" gutterBottom>
                      <b>Radar created by: </b>
                      {radar?.createdByUser?.name}
                    </Typography>
                    <Typography variant="body1" gutterBottom>
                      <b>Radar created at: </b>
                      {radar?.createdAt
                        ? new Date(radar?.createdAt).toLocaleDateString(
                            "nl-NL",
                            {
                              minute: "numeric",
                              hour: "numeric",
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                            },
                          )
                        : "N/A"}
                    </Typography>
                  </Stack>
                  <Stack
                    direction="row"
                    alignItems="flex-start"
                    justifyContent="space-between"
                    mt={2}
                    gap="2rem"
                  >
                    <Stack
                      direction="row"
                      alignItems="flex-start"
                      justifyContent="space-between"
                      gap="2rem"
                    >
                      <Button
                        variant="contained"
                        style={{ textTransform: "none" }}
                        color="primary"
                        onClick={() => setOpenSaveChangesDialog(true)}
                        startIcon={<Iconify icon="bx:save" />}
                      >
                        Save
                      </Button>
                      <Button
                        variant="contained"
                        color="discard"
                        onClick={() => setOpenDiscardChangesDialog(true)}
                        startIcon={<CancelIcon />}
                      >
                        Discard
                      </Button>
                    </Stack>
                    <Button
                      sx={{ textTransform: "none" }}
                      variant="contained"
                      color="error"
                      onClick={() => setOpenRemoveRadarDialog(true)}
                      startIcon={<Iconify icon="ion:trash-bin" />}
                    >
                      Remove radar
                    </Button>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Container>
          {openRemoveRadarDialog && (
            <ConfirmationDialog
              onConfirm={(inputText) => handleRemoveRadar(inputText)}
              onCancel={() => setOpenRemoveRadarDialog(false)}
              title={"Are you sure you want to remove this radar?"}
              description={
                "This action will remove the radar from the system. The radar will be permanently deleted after a period of time. Please note that, the deleted radar cannot be used for any purpose." +
                " You can only delete radar created by you. If you want to remove a radar created by another user, please contact someone (admin) who has the permission to do so."
              }
            />
          )}
          {openDiscardChangesDialog && (
            <AlertDialog
              onConfirm={handleDiscardChanges}
              onCancel={() => setOpenDiscardChangesDialog(false)}
              title={"Are you sure you want to discard the changes?"}
              description={
                "This action will discard the changes you made. This action cannot be undone. You will be redirected to the radars page."
              }
            />
          )}
          {openSaveChangesDialog && (
            <AlertDialog
              onConfirm={handleSaveChanges}
              onCancel={() => setOpenSaveChangesDialog(false)}
              title={"Are you sure you want to save the changes?"}
              description={
                "This action will save the changes you made. This action cannot be undone. You will be redirected to the radars page."
              }
            />
          )}
        </Page>
      )}
    </>
  );
}
