import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { Alert, Box, Container, Grid, Stack, Typography } from "@mui/material";

// material
import {
  ArrowUpward,
  Done as DoneIcon,
  People as PeopleIcon,
  PriorityHigh as PriorityHighIcon,
  TrackChanges as TrackChangesIcon,
} from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

// Components
import Page from "@/components/Page";
import { AppCard } from "@/components/_dashboard/app";

// Contexts
import { RadarContext, RadarContextType } from "@/Contexts/RadarContext";
import {
  SoftwareContextType,
  SoftwareContext,
} from "@/Contexts/SoftwareContext";
import {
  UploadedVersionContext,
  UploadedVersionContextType,
} from "@/Contexts/UploadedVersionContext";
import { UserContext, UserContextType } from "@/Contexts/UserContext";
import { AuthContext, AuthContextType } from "@/Contexts/AuthContext";

// Sections
import { RadarsByType, SizeInfo } from "@/sections/@dashboard/home";
import NotFound from "@/pages/Page404";

// ----------------------------------------------------------------------

export default function DashboardApp() {
  // Contexts
  const { users } = useContext(UserContext) as UserContextType;
  const { radars, filteredRadars, getRadarCountBySoftwareType } = useContext(
    RadarContext,
  ) as RadarContextType;
  const { softwares, sizeMetrics } = useContext(
    SoftwareContext,
  ) as SoftwareContextType;
  const { countSoftwares } = useContext(
    UploadedVersionContext,
  ) as UploadedVersionContextType;
  const { user } = useContext(AuthContext) as AuthContextType;

  const radarsByType = getRadarCountBySoftwareType();

  // For charts
  const theme = useTheme();

  return !user ? (
    <Page title="404 | Not Found">
      <NotFound />
    </Page>
  ) : (
    <Page title="Home | Robin Radar Systems">
      <Container maxWidth="xl">
        <Box sx={{ pb: 5 }}>
          <Typography variant="h4">
            Welcome back, {user.name.split(" ")[0]}
          </Typography>
        </Box>
        {/* display alert if user has not changed default password */}
        {!user.passwordChanged && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ mb: 2 }}
          >
            <Alert severity="error">
              You have not changed your default password yet! Please change it
              now at your{" "}
              <Link
                to="/dashboard/account#change-password"
                style={{ textDecoration: "none" }}
              >
                Account
              </Link>{" "}
              page.
            </Alert>
          </Stack>
        )}
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={3}>
            <AppCard
              title="Users"
              color="info"
              TOTAL={users.length}
              Icon={PeopleIcon}
              route={"/dashboard/user/list"}
              userRole={user.role}
              allowedRoles={["admin", "S&S"]}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <AppCard
              title="Radars"
              color="primary"
              TOTAL={radars.length}
              Icon={TrackChangesIcon}
              route={"/dashboard/radars/list"}
              userRole={user.role}
              allowedRoles={["admin", "S&S"]}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppCard
              title="Released softwares"
              TOTAL={softwares.length}
              Icon={DoneIcon}
              color="success"
              route={"/dashboard/softwares/installed"}
              userRole={user.role}
              allowedRoles={["admin", "R&D"]}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppCard
              title="Radar(s) with no software assigned"
              TOTAL={filteredRadars.length}
              Icon={PriorityHighIcon}
              color="error"
              route={"/dashboard/radars/list"}
              queryParams="?filter=no-software"
              userRole={user.role}
              allowedRoles={["admin", "S&S"]}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <AppCard
              title="Uploaded softwares"
              TOTAL={countSoftwares}
              Icon={ArrowUpward}
              color="warning"
              route={"/dashboard/softwares/uploaded"}
              userRole={user.role}
              allowedRoles={["admin", "R&D"]}
            />
          </Grid>
          {/* This Grid item will take the remaining space in the row and push the next item to a new line */}
          <Grid item xs={12} md={6} lg={8} />
          <Grid item xs={12} md={6} lg={8}>
            <RadarsByType
              title="Radars by type"
              subheader="(ELVIRA, BIRDRADAR, IRIS)"
              chartData={radarsByType.map((radar) => ({
                label: radar.type,
                value: radar.value,
              }))}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            {sizeMetrics && sizeMetrics.chartData && (
              <SizeInfo
                title="Software files size metrics"
                chartData={sizeMetrics.chartData}
                chartColors={[
                  theme.palette.primary.main,
                  theme.palette.info.main,
                  theme.palette.warning.main,
                  theme.palette.error.main,
                ]}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
}
