import React from "react";
import { Box, Typography } from "@mui/material";
import ReactLoading from "react-loading";

type Props = {
  noTitle?: boolean;
};

const Loader = ({ noTitle }: Props) => {
  return (
    <Box>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        {!noTitle && (
          <Typography variant="h4" color="primary">
            Robin Radar Systems
          </Typography>
        )}
        <ReactLoading
          type={"spinningBubbles"}
          color={"#00AB55"}
          height={50}
          width={50}
        />
      </Box>
    </Box>
  );
};

export default Loader;
