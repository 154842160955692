import { useArray } from "@/hooks";
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { toast } from "react-toastify";
import { handleCatch, makeReq } from "@/utils/makeReq";
import { AuthContext, AuthContextType } from "@/Contexts/AuthContext";
import { ChildrenProp } from "@/utils/types";
import { UserDTO, UserModel } from "@/models/user";
import { APIResponse, CallbackProp } from "@/Contexts/types";

export type UserContextType = {
  loading: boolean;
  users: UserModel[];
  deletedUsers: UserModel[];
  getUserById: (id: string) => UserModel | undefined;
  getDeletedUserById: (id: string) => UserModel | undefined;
  createUser: (
    val: UserDTO,
    callback?: CallbackProp,
  ) => Promise<void | APIResponse<{ user: UserModel }>>;
  editUser: (
    id: string,
    state: Partial<UserDTO>,
    callback?: CallbackProp,
  ) => Promise<void>;
  deleteUser: (id: string, deleteReason: string) => Promise<void>;
  deleteUserPermanently: (id: string) => Promise<void>;
  restoreUser: (id: string) => Promise<void>;
  getDeletedUsers: () => Promise<void>;
  softDeleteMultipleUsers: (
    ids: string[],
    deleteReason: string,
  ) => Promise<void>;
};

export const UserContext = createContext<UserContextType | null>(null);

type Props = {
  children: ChildrenProp;
};

export const UsersProvider = ({ children }: Props) => {
  const [loading, setLoading] = useState(true);
  const { user } = useContext(AuthContext) as AuthContextType;

  // Custom hook to manage users.
  const {
    array: users,
    setArray: setUsers,
    push: pushUser,
    update: updateUser,
    remove: removeUser,
  } = useArray<UserModel>([], "_id");

  // Custom hook to manage DELETED users.
  const {
    array: deletedUsers,
    setArray: setDeletedUsers,
    remove: removeDeletedUser,
  } = useArray<UserModel>([], "_id");

  const fetchUsers = useCallback(async () => {
    try {
      const resData = await makeReq<
        undefined,
        APIResponse<{ users: UserModel[] }>
      >(`/users`);
      setUsers(resData.users);
    } catch (err) {
      handleCatch(err as Error);
    } finally {
      setLoading(false);
    }
  }, [setUsers]);

  const getUserById = (id: string) => {
    return users.find((el) => el._id === id);
  };

  const getDeletedUserById = (id: string) => {
    return deletedUsers.find((el) => el._id === id);
  };

  // Fetch it once user logged in
  useEffect(() => {
    if (!user) return;
    fetchUsers();
  }, [user, fetchUsers]);

  // * CRUD Operations

  const getDeletedUsers = async () => {
    try {
      const resData = await makeReq<
        undefined,
        APIResponse<{ users: UserModel[] }>
      >(`/users?deleted=true`);
      setDeletedUsers(resData.users);
    } catch (err) {
      handleCatch(err as Error);
    } finally {
      setLoading(false);
    }
  };

  const createUser = async (val: UserDTO, callback?: CallbackProp) => {
    try {
      const resData = await makeReq<UserDTO, APIResponse<{ user: UserModel }>>(
        `/auth/create`,
        { body: val },
        "POST",
      );
      toast.success("User Created Successfully!");
      pushUser(resData.user);
      callback?.();
      return resData;
    } catch (err) {
      handleCatch(err as Error);
      return Promise.resolve();
    }
  };

  const editUser = async (
    id: string,
    state: Partial<UserDTO>,
    callback?: CallbackProp,
  ) => {
    try {
      const resData = await makeReq<
        Partial<UserDTO>,
        APIResponse<{ user: UserModel }>
      >(`/users/${id}`, { body: state }, "PATCH");
      toast.success("User Updated Successfully!");
      updateUser(id, resData.user);
      callback?.();
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const restoreUser = async (id: string) => {
    try {
      // set deleted property to false, in order to restore the user
      const resData = await makeReq<
        {
          deleted: boolean;
          deleteReason: null;
          deletedAt: null;
        },
        APIResponse<{
          user: UserModel;
        }>
      >(
        `/users/${id}`,
        {
          body: { deleted: false, deleteReason: null, deletedAt: null },
        },
        "PATCH",
      );
      toast.success("User Restored Successfully!");
      removeDeletedUser(id);
      pushUser(resData.user);
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const deleteUser = async (id: string, deleteReason: string) => {
    try {
      // set deleted property to true, to soft delete the user + set activated to false
      await makeReq<
        {
          deleted: boolean;
          activated: boolean;
          deleteReason: string;
          deletedAt: number;
        },
        APIResponse
      >(
        `/users/${id}`,
        {
          body: {
            deleted: true,
            activated: false,
            deleteReason,
            deletedAt: Date.now(),
          },
        },
        "PATCH",
      );
      toast.success("User Deleted Successfully!");
      removeUser(id);
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  // Soft delete multiple users (backend will set deleted property to true for each user)
  const softDeleteMultipleUsers = async (
    ids: string[],
    deleteReason: string,
  ) => {
    try {
      await makeReq<{ ids: string[]; deleteReason: string }, APIResponse>(
        `/users/softdelete-multiple`,
        {
          body: { ids, deleteReason },
        },
        "PATCH",
      );

      // remove deleted users from users array (soft deleted), to update the UI state
      ids.forEach((id) => {
        removeUser(id);
      });

      toast.success("Users Deleted Successfully!");
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  const deleteUserPermanently = async (id: string) => {
    try {
      await makeReq<undefined, APIResponse>(`/users/${id}`, {}, "DELETE");
      toast.success("User Deleted Permanently!");
      removeDeletedUser(id);
    } catch (err) {
      handleCatch(err as Error);
    }
  };

  return (
    <UserContext.Provider
      value={{
        loading,
        users,
        getUserById,
        createUser,
        deleteUser,
        editUser,
        getDeletedUsers,
        deletedUsers,
        getDeletedUserById,
        restoreUser,
        deleteUserPermanently,
        softDeleteMultipleUsers,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
